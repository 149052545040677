.shibboleth {
    &__buttons {
        margin-bottom: toRem(24);
        .btn {
            width: 100%;
            min-height: toRem(44);

            @include media-breakpoint-up(md) {
                width: auto;
                min-height: toRem(34);
            }
        }
    }

    &__link {
        text-decoration: underline;
    }
}
