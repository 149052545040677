.content-navigation {
    width: 100%;
    padding: 0 toRem(24);

    @include media-breakpoint-up(md) {
        width: auto;
        margin-bottom: toRem(4);
    }

    .btn {
        color: $black;
        @include media-breakpoint-down(lg) {
            line-height: 1.5;
        }

        &.disabled {
            opacity: 1;
            color: $content-navigation-disable-link-color;
        }
    }
}
