.announcement {
    $this-announcement: &;

    &.journal-announcement {
        padding: toRem(32) toRem(16) toRem(45);

        @include media-breakpoint-up(lg) {
            padding: toRem(32) toRem(284) toRem(45);
        }

        .card {
            .meta-wrapper {
                hr {
                    height: toRem(18);
                }
            }

            &-contribs {
                a:hover {
                    color: $gray;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding: toRem(32) toRem(148) toRem(45);
    }

    &__slideshow {
        .announcementContainer {
            background-color: $primary-var;
        }

        .announcement {
            background-color: $primary-var;
        }

        .swiper {
            &-pagination {
                &-bullets {
                    bottom: toRem(28);
                }
            }
        }

        &__shadow {
            background-color: $announcement-slider-shadow-lighter;
            opacity: 0.3;

            & + & {
                background-color: $announcement-slider-shadow;
                opacity: 0.6;
            }
        }
    }
}

.slideshow {
    .swiper {
        &-button {
            &-container {
                @include media-breakpoint-up(sm) {
                    width: 100%;
                    left: 0;
                }

                @include media-breakpoint-up(md) {
                    width: calc(100% + #{toRem(80)});
                    left: toRem(-40);
                }

                .slider__container & {
                    @include media-breakpoint-up(lg) {
                        width: 100%;
                        left: 0;
                    }

                    @include media-breakpoint-up(xl) {
                        width: calc(100% + #{toRem(80)});
                        left: toRem(-40);
                    }
                }
            }
        }
    }
}

.card-slide {
    position: relative;
    display: flex;
    align-items: start;
    justify-content: center;
    height: 100%;
    min-height: toRem(300);

    &__content {
        position: relative;
        z-index: map-get($map: $z-index-map-app, $key: 'slideshow-title');
        color: $white;
        text-shadow: $slideshow-title-text-shadow;
        max-width: 85%;
        @include media-breakpoint-up('md') {
            width: toRem(816);
        }
    }
    &__title {
        @include set-typography-type($text-title-2);
    }

    .brand-tag {
        padding: toRem(2) 0;
        border-radius: toRem(28);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: map-get($map: $z-index-map-app, $key: 'slideshow-overlay');
        width: 100%;
        height: 100%;
        background: linear-gradient(
            to left,
            $slideshow-overlay-color-light 0%,
            $slideshow-overlay-color-dark 50%,
            $slideshow-overlay-color-light 100%
        );
    }

    .slideshow__background {
        position: absolute;
        top: 0;
        left: 0;
        z-index: map-get($map: $z-index-map-app, $key: 'slideshow-background');
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.full-width-slideshow {
    .slideshow {
        .swiper-container {
            padding-bottom: 0;
        }

        .swiper-slide {
            padding-bottom: 0;
        }

        .swiper-container-horizontal {
            .swiper-pagination-bullets {
                bottom: toRem(40);

                .swiper-control-btn {
                    width: toRem(20);
                    height: toRem(20);

                    i {
                        width: toRem(16);
                        height: toRem(16);
                    }
                }
            }

            .swiper-pagination-bullet {
                background-color: $white;
                opacity: 1;
                width: toRem(13);
                height: toRem(13);
                margin: 0 toRem(4);

                &-active {
                    box-shadow: 0 0 0 toRem(5) $primary;
                }
            }
        }
    }
}

.slideshow-with-cover-images {
    .slideshow {
        .swiper-container {
            height: toRem(500);

            // Breakpoints to control specific heights
            @media only screen and (min-width: $swiper-breakpoint-custom-400) { /*stylelint-disable-line*/
                height: toRem(300);
            }

            @media only screen and (min-width: $swiper-breakpoint-custom-540) { /*stylelint-disable-line*/
                height: toRem(380);
            }

            @include media-breakpoint-up(sm) {
                height: toRem(320);
            }

            @include media-breakpoint-up(md) {
                height: toRem(380);
            }

            @media only screen and (min-width: $swiper-breakpoint-custom-835) { /*stylelint-disable-line*/
                height: toRem(450);
            }

            @include media-breakpoint-up(lg) {
                height: toRem(344);
            }

            @include media-breakpoint-up(xl) {
                height: toRem(394);
            }
            // Initial state to hide the slideshow
            opacity: 0;
            transition: opacity 0.3s ease;
        }
        .swiper-container.loaded {
            // Show the slideshow when loaded
            opacity: 1;
        }
    }
}
