.test-summary {
    .header__title {
        @include set-typography-type($text-large-bold);
    }

    &__header-banner {
        gap: $test-summary-list-header-banner-gap;
        background-color: $primary-var;
    }

    &__form-header {
        @include set-typography-type($text-large-bold);
        padding-bottom: toRem(8);
        border-bottom: toRem(2) solid $primary-var;
    }

    &__claim-credits {
        &__form {
            background-color: $light-gray;

            .form-control {
                min-width: toRem(100);
                border-color: $gray;
            }
        }
    }

    &__card--heading {
        @include set-typography-type($text-large-bold);
        &__links {
            display: flex;
            flex-wrap: wrap;
            gap: toRem(8);
        }
    }

    &__card-content {
        &__title {
            font-weight: 700;
            border-bottom: toRem(0.5) solid $light-gray;
        }
        font-weight: 700;
    }

    &__title {
        @include set-typography-type($text-large-bold);
        background-color: $primary-var;
        color: $white;
    }

    &__list__item {
        border-bottom: toRem(0.5) solid $light-gray;

        &__value {
            color: $test-summary-list-value-color;
            &.failed {
                color: $test-summary-list-value-fail-color;
            }
        }
    }

    &__book-cover {
        display: flex;
        justify-content: center;
        img {
            max-width: 100%;
        }
    }
}
