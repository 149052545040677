.back-to-top {
    $this: &;

    &__action {
        &__ring {
            .back-to-top--snapped & {
                stroke: $back-to-top-ring-bg-color;
            }
        }

        &__ring-bg {
            .back-to-top--snapped & {
                stroke: $back-to-top-ring-bg-color;
            }
        }

        &-icon {
            #{$icon-selector} {
                .back-to-top--snapped & {
                    color: $back-to-top-ring-bg-color;
                }
            }
        }
    }
}
