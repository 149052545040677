.digital-question-test-list {
    .table {
        display: flex;
        flex-direction: column;

        &__expandable {
            thead {
                display: flex;
            }

            tbody {
                display: flex;
                flex-direction: column;
            }

            tr {
                display: flex;
                width: 100%;
            }

            th {
                border-top: 0;
                @include set-typography-type($text-regular-bold-16);
                text-align: left;
                flex: 1;
                border-bottom: toRem(1) solid $primary;

                &.large {
                    flex: 2;
                }

                &.small {
                    flex: 0.7;
                }
            }

            td {
                border-top: 0;
                color: $deep-gray;
                flex: 1;

                &.large {
                    flex: 2;
                }

                &.small {
                    flex: 0.7;
                }

                @include set-typography-type($text-small);

                text-align: left;

                &:last-child {
                    border-right: none;
                }

                a {
                    text-decoration: none;
                    color: $primary;
                }

                .completed {
                    color: $digital-questions-list-completed-text-color;
                }

                &.digital-question-test-list__item__links {
                    display: flex;
                    justify-content: space-between;

                    &::before {
                        display: none;
                    }
                }
            }

            @include media-breakpoint-down($expandable-table-breakpoint) {
                thead {
                    display: none;
                }

                tr {
                    padding-top: toRem(16);
                    flex-direction: column;
                }

                td {
                    border-right: none;
                    display: flex;
                    gap: toRem(4);
                    @include set-typography-type($text-small);

                    margin: toRem(4) 0;

                    a {
                        color: $primary;
                    }

                    &:not(:first-child) {
                        margin-top: toRem(2);
                        text-align: left;
                    }

                    &::before {
                        display: none;
                    }

                    &.digital-question-test-list__item__links {
                        display: flex;
                        justify-content: space-between;

                        &.as-btn {
                            background-color: $white;
                            padding: toRem(8);
                            margin: toRem(8) 0;
                        }

                        &.last-item {
                            order: 1;
                            justify-content: end;
                        }
                    }

                    &:first-child {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    &__top {
        display: flex;
        flex-direction: column;

        padding-bottom: toRem(12);
        border-bottom: toRem(2) solid $primary;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: toRem(24);

            &-right {
                flex-wrap: wrap;
            }
        }
    }

    &__sort-select {
        &.btn {
            border-bottom: toRem(2) solid $primary;
            width: toRem(130);
            margin-bottom: toRem(12);
            color: $primary;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }
    }

    &__only-completed {
        .custom-control-label {
            display: inline-block;
            font-weight: 700;

            &::before {
                border-color: $primary;
            }
        }
    }

    &__test-info {
        padding-top: toRem(12);
        border-top: toRem(2) solid $primary;
    }

    &__cme {
        &__title {
            padding: toRem(16) 0;
            border-bottom: toRem(2) solid $primary;
        }

        p {
            margin: toRem(16) 0;
        }
    }

    &__item {
        &__links {
            .info-btn {
                all: unset;
                cursor: pointer;
            }
            .icon-component {
                margin-left: toRem(4);

                svg {
                    width: toRem(22);
                    height: toRem(22);
                }
            }
        }
    }
}
