.hero-slider {
    position: initial;
    padding: toRem(40) 0 toRem(184);

    @include media-breakpoint-up(md) {
        padding: toRem(65) 0 toRem(111);
    }

    @include media-breakpoint-up(xl) {
        padding: toRem(48) 0 toRem(116);
    }

    .card-body {
        @include set-typography-type($text-large-2);

        .hero-pn & {
            @include set-typography-type($text-regular);
        }
    }

    .card-category {
        padding: toRem(4) toRem(12);
        line-height: 1.2;
    }
}

.hero-pn {
    &::after {
        background-color: rgba(0, 0, 0, 0.353);
    }
}
