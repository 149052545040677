.facet {
    &__ctrl {
        height: $search-facet-control-sm-height;

        & > .icon-component {
            svg {
                height: toRem(22);
                width: toRem(22);
            }
        }

        &--nested {
            display: flex;
            align-items: center;
            justify-content: end;
        }
    }

    &__applied {
        &__btn {
            text-transform: capitalize;

            & > span:first-of-type {
                padding-bottom: toRem(2);
            }
        }
    }

    &__date {
        &__chart {
            &__apply-btn {
                margin-right: toRem(-22);

                .btn {
                    height: toRem(32);
                    padding: toRem(4) toRem(16);
                    width: max-content;
                }
            }
        }
    }
}
