.test-questions-list {
    overflow: auto;
    margin-bottom: 0;

    @include media-breakpoint-up(sm) {
        max-height: $test-questions-list-max-height;

        .test-widget & {
            max-height: initial;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: toRem(24);

        &:last-child {
            margin-bottom: 0;
        }

        .form-group {
            margin-bottom: 0;
        }

        .custom-checkbox {
            .custom-control-input {
                &:checked:disabled {
                    & ~ .custom-control-label {
                        &::before {
                            background-color: $primary-var;
                            border-color: $primary-var;
                        }

                        &.success {
                            &::before {
                                background-color: $success;
                                border-color: $success;
                            }
                        }

                        &.error {
                            &::before {
                                background-color: $danger;
                                border-color: $danger;
                            }

                            &::after {
                                background-image: escape-svg(
                                    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='%23ffffff' viewBox='4 4 8 8'%3E%3Cpath d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708' stroke='%23ffffff' stroke-width='1'/%3E%3C/svg%3e")
                                );
                            }
                        }
                    }
                }
            }
        }

        &__icon {
            .icon-component {
                margin-left: toRem(8);
                svg {
                    height: toRem(18);
                    width: toRem(18);
                }
            }
        }
    }

    &__sidebar {
        @extend .d-sm-block;
        width: 100%;
        flex: auto;
        overflow: hidden auto;

        &:not(.show) {
            transform: translateX(100%);
        }

        @include media-breakpoint-up(sm) {
            position: static;
            opacity: 1;
            overflow: visible;
            visibility: visible;
            transform: none;
            min-width: 0;

            &:not(.show) {
                transform: translateX(0);
            }
        }
    }
}
