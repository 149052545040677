.saved-search {
    &__body {
        &__item {
            &--actions {
                &--run {
                    @include set-typography-type($text-mini-bold-uppercase);
                    color: $saved-search-run-color;
                }

                @include media-breakpoint-up(md) {
                    padding-right: toRem(16);

                    &--delete {
                        padding-left: toRem(2);
                    }
                }

                @include media-breakpoint-up(lg) {
                    padding-right: toRem(16);

                    &--delete {
                        padding-left: toRem(16);
                    }
                }
            }

            &--query-name {
                @include set-typography-type($text-mini);
                .profile-main & {
                    color: $dark-gray;
                }
            }

            &__value {
                @include set-typography-type($text-mini);
                text-transform: capitalize;
            }
        }
    }

    &__heading {
        @include media-breakpoint-up(md) {
            border-bottom: toRem(1) solid $very-light-gray;
            .profile-main & {
                border-bottom: toRem(4) solid $gray;
            }
        }

        & > .col {
            .profile-main & {
                @include media-breakpoint-up(md) {
                    padding-left: 0;
                }
            }
        }
    }

    &__row {
        @include set-typography-type($text-mini-bold-uppercase);

        & > .col {
            .profile-main & {
                padding: 0 toRem(12);

                @include media-breakpoint-up(md) {
                    padding: 0 toRem(16) 0 0;

                    &:last-child {
                        padding: 0;

                        .btn {
                            padding-left: toRem(8);
                        }
                    }
                }

                @include media-breakpoint-up(lg) {
                    &:last-child {
                        padding: 0 toRem(16) 0 0;

                        .btn {
                            padding-left: toRem(12);
                        }
                    }
                }
            }
        }
    }
}
