.holdings {
    h4 {
        @include set-typography-type($text-regular-bold);
    }

    .btn {
        width: 100%;
        min-height: toRem(44);

        @include media-breakpoint-up(md) {
            width: auto;
            min-height: toRem(34);
        }
    }

    &__hint {
        margin-top: toRem(16);
    }
}
