.podcast {
    &__subscribe {
        &-links {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            justify-content: space-between;
            row-gap: toRem(48);
            column-gap: toRem(48);

            @include media-breakpoint-up(md) {
                display: flex;
                align-items: center;
                gap: toRem(24);
            }
        }
    }
}
