@mixin ellipsis($max-width: false, $numberOfLine: false) {
    @if $max-width {
        max-width: toRem($max-width);
        white-space: nowrap;
    }

    @if ($numberOfLine) {
        display: -webkit-box;
        -webkit-line-clamp: $numberOfLine;
        -webkit-box-orient: vertical;
    }

    text-overflow: ellipsis;
    overflow: hidden;
}
