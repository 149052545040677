.adplaceholder {
    padding: toRem(22) toRem(36);

    @include media-breakpoint-up(md) {
        padding: toRem(22) toRem(32);
    }

    @include media-breakpoint-up(lg) {
        padding: toRem(24) toRem(42);
    }

    img {
        width: 100%;
    }

    &__title {
        top: toRem(-38);
        color: $dark-gray;
        padding: toRem(10) toRem(10);
    }
}
