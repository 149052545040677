$z-index-map-app: (
    'login-bar-icon': 1025,
    'secondary-navbar': 5,
    'header-quick-search-result': 105,
    'header-ad-placeholder': 1026,
    'main-header': 1024,
    'sidebar-overlay': 1030,
    'institution-banner': 1000,
    'search-results-advanced': 5,
    'do-banner-list': 1023,
    'do-banner-list-sm': 1027,
    'tfa': 1030,
    'article-header': 1,
    'slideshow-title': 1,
    'slideshow-overlay': 0,
    'slideshow-background': -1,
    'toc-book-btn': 2,
);

$primary-var: var(--primary);
$secondary-var: var(--secondary);

//Footer
$footer-background-color: $primary;
$footer-social-media-icon-margin: 0 toRem(10) 0 0;
$footer-social-media-max-width: toRem(280);

//Footer slideshow
$footer-slideshow-bg: $primary;
$footer-slideshow-items-padding: toRem(16) toRem(82) toRem(16) 0;

// Navbar
$navbar-light-border: none;
$main-navbar-z-index: auto;

// Institution Banner
$institution-banner-height: toRem(39);

// quick search single
$quick-search-single-placeholder-color: $darker-gray;

// Hero
$hero-min-height: auto;
$hero-gradiant-bg: linear-gradient(180deg, $black 0%, rgba($black, 0.74) 98.94%, rgba($black, 0) 99.99%);
$hero-opacity: 0.65;

// Hero slider
$hero-slider-bullet: $dark-gray;

//Announcements Slider
$announcement-slider-padding: toRem(32) toRem(32) toRem(45);
$announcement-slider-shadow: $primary-var;
$announcement-slider-shadow-lighter: $primary-var;

//Slideshow
$slideshow-title-text-shadow: 0 toRem(-2) toRem(4) rgba(0, 0, 0, 0.45);
$slideshow-overlay-color-dark: rgba(0, 0, 0, 0.8);
$slideshow-overlay-color-light: rgba(0, 0, 0, 0.2);
$slideshow-swiper-control-btn-color: $black;

//ad-placeholder
$placeholder-exist-border: toRem(1) solid $gray;

//Back to top
$back-to-top-ring-color: $primary-var;
$back-to-top-ring-bg-color: $secondary-var;
$back-to-top-action-icon-color: $primary-var;

//Tabs (Dropdown)
$tabs-dropdown-text-color: $deep-gray;
$branded-tabs-color: $deep-gray;

// Journal Article Box
$journalArticleBoxShadow: 0 toRem(1) toRem(20) 0 rgba(11, 11, 11, 0.24);

// colors
$pdf-tag-hover-bg-color: #d12a2a;
$dividers: #d9d9d9;
$link-colors: #155bc3;

// Videos Thumb
$video-thumb-img-bg-opacity: 0.64;
$video-thumb-img-bg: linear-gradient(
    180deg,
    #0b0b0b 0%,
    rgba(11, 11, 11, 0.74) 50.94%,
    rgba(0, 0, 0, 0) 99.99%,
    rgba(11, 11, 11, 0) 100%
);

// Past Issues
$past-issue-overlay-color: rgba(var(--primary-rgb), 0.9);

// Tabs
$tabs-dropdown-active-item-text-hover-color: $deep-gray;
$tabs-dropdown-item-hover-color: $light-gray;

// LOI
$loi-content-margin-sm: toRem(40) -1rem toRem(48);
$loi-content-padding-md: 0 1rem;
$loi-content-padding: 0;
$loi-content-margin: toRem(32) 0 toRem(48);

$loi-nested-tabs-active-border-color: $primary-var;
$loi-past-issue: 0 toRem(60);
$loi-content-padding-sm: toRem(20) 0 0;
$loi-past-issue-box-shadow: 0 toRem(1) toRem(20) 0 rgba(11, 11, 11, 0.24);

// Quick Search
$qs-advanced-search-btn-box-shadow: 0 toRem(3) toRem(8) 0 rgba(11, 11, 11, 0.18);

// Pagination
$pagination-disabled-color: $gray;
$quick-search-single-input-padding: 0 1.5rem 0 0;
$quick-search-single-input-height: 2rem;
$qucik-search-autocomplete-z-index: 1015;

//search result
$search-result-refine-search-toggle-icon-size: toRem(9);
$search-result-nav-actions-color: $darker-gray;

//search facet
$search-facet-control-sm-height: toRem(40);
$search-facet-control-border-color: var(--primary);
$search-facet-applied-padding: toRem(4) toRem(12);
$search-facet-content-padding: 0 toRem(16) 0 toRem(6);
$search-facet-range-slider-disabled-color: var(--secondary);
$search-facet-chart-date-apply-btn-margin-top: toRem(64);
$search-facet-control-border-color: var(--primary);

// sidebar
$sidebar-overlay: rgba(11, 11, 11, 0.4);

// Tabs
$branded-tabs-active-border-color: $primary-var;

// Saved Search
$saved-search-run-color: $deep-gray;
$saved-search-delete-color: $deep-gray;

// Advanced Search
$advanced-search-tips-title-border-left-color: $primary-var;

// Article actions bar
$article-actionbar-icons-colors: $primary-var;
$article-action-border-color: $primary-var;

// Sections navigation
$sections-navigation-container-top: var(--totalHeaderHeight);
$sections-navigation-box-shadow: 0 toRem(3) toRem(8) 0 rgba(11, 11, 11, 0.18);
$sections-navigation-container-mt: toRem(60);
$sections-navigation-links-color: #757575;

//issue-sidebar
$issue-sidebar-background: linear-gradient(180deg, hsla(0, 0%, 93.3%, 0) toRem(34), $light-gray 0);

// profile menu
$profile-menu-title-left-border-color: $primary-var;
$profile-menu-active-item-dot-color: $primary-var;

//expandable table
$expandable-table-thead-sm-margin: toRem(8) 0 toRem(2);
$expandable-table-tr-border: toRem(1) solid $gray;
$expandable-table-tr-padding: 0 0 1rem;

// education access entitlement
$education-access-entitlement-note-bg-color: #ffd4d4;

// DO
$card-ocean-bg-color: #f4f8ff;

// Digital questions list
$digital-questions-list-completed-text-color: #008425;

// Questions List
$test-questions-list-max-height: toRem(500);

// Test Widget
$test-widget-timer-bg-color: $secondary;
$test-widget-card-bg-color: $light-gray;
$test-widget-nav-border-color: $gray;
$test-widget-nav-title-number-color: $gray;
$test-widget-note-border-color: $gray;
$test-widget-order-list-item-border-color: $gray;
$test-widget-order-list-selected-item-bg-color: $very-light-gray;
$test-widget-order-list-selected-item-border-color: $deep-gray;

// Test Summary
$test-summary-list-value-color: $primary-var;
$test-summary-list-value-fail-color: $primary;
$test-summary-list-header-banner-gap: toRem(16);

// Custom Pages
$meta-wrapper-z-index: -1;

// PN Links Boxes
$pn-link-box-show: 0 toRem(3) toRem(8) 0 rgba(11, 11, 11, 0.18);

// Content navigation
$content-navigation-disable-link-color: #757575;

// Menu
$menu-dropdown-border: toRem(1) solid rgba(0, 0, 0, 0.15);
$menu-dropdown-border-radius: toRem(4);
$menu-rotate-deg-135: rotate(-135deg);
$menu-rotate-deg-45: rotate(-45deg);
$menu-dropdown-link-sm-font-size: toRem(14);
$menu-dropdown-link-xl-font-size: toRem(12);
$menu-dropdown-link-sm-font-weight: 500;
$menu-dropdown-link-xl-font-weight: 700;
$menu-dropdown-link-xl-padding: toRem(8) toRem(16);
$submenu-dropdown-toggle-bg-color: #ebebeb;

//Tabs (Dropdown)
$branded-tabs-text-transform: none;

// Swiper
$swiper-breakpoint-custom-400: 400px; /*stylelint-disable-line*/
$swiper-breakpoint-custom-540: 540px; /*stylelint-disable-line*/
$swiper-breakpoint-custom-835: 835px; /*stylelint-disable-line*/
