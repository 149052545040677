.footer {
    &__sitemap {
        display: grid;
        justify-content: center;
        text-align: center;
        row-gap: toRem(24);

        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: max-content max-content;
            column-gap: toRem(56);
            row-gap: toRem(56);
            text-align: left;
            justify-content: flex-end;
        }

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: flex-end;
            column-gap: toRem(56);
        }
    }

    &__logo {
        max-width: toRem(200);

        @include media-breakpoint-up(md) {
            max-width: toRem(280);
        }
    }

    &-pn-banner {
        img {
            max-width: toRem(208);

            @include media-breakpoint-up(md) {
                max-width: max-content;
            }
        }

        &__links {
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(md) {
                flex-direction: row;
            }

            a {
                padding-bottom: toRem(24);
                margin-bottom: toRem(24);
                border-bottom: toRem(1) solid $deep-gray;

                @include media-breakpoint-up(md) {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: 0;
                    border-right: toRem(1) solid $deep-gray;
                    padding-right: toRem(16);
                    margin-right: toRem(16);
                }
            }
        }
    }

    &__social-media {
        .x {
            svg {
                width: toRem(20);
            }
        }
    }
}
