.video-list-wrapper {
    background-color: $very-light-gray;
}

.video-thumb {
    &__title {
        @include set-typography-type($text-title-5);
        color: $white;
        @include ellipsis($numberOfLine: 2);
        margin-top: toRem(16);
    }

    &__image {
        position: relative;
        &-overlay {
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background: $video-thumb-img-bg;
            opacity: $video-thumb-img-bg-opacity;
        }
    }

    .btn-circle {
        margin: 0;
    }

    &--large {
        .btn-circle {
            @include media-breakpoint-up(md) {
                min-width: toRem(40);
                min-height: toRem(40);
                margin-right: toRem(24);

                .icon-component {
                    font-size: toRem(18);
                }
            }
        }

        .video-thumb__caption {
            @include media-breakpoint-up(md) {
                flex-wrap: nowrap;
            }
        }

        .video-thumb__title {
            margin-top: 0;
        }
    }
}
