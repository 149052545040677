.test-widget {
    &__header {
        background-color: $primary-var;
        padding: toRem(16);
        color: $white;
        margin: 0 toRem(-16);

        @include media-breakpoint-up(sm) {
            width: initial;
            margin: 0;
        }

        &__link {
            background-color: $secondary-var;
            color: $primary-var;
        }

        &__links {
            li:not(.complete-link) {
                width: 100%;

                @include media-breakpoint-up(md) {
                    width: initial;
                }

                .btn {
                    width: 100%;

                    @include media-breakpoint-up(md) {
                        width: initial;
                    }
                }
            }
        }
    }

    &__message {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: toRem(10);
        color: $danger;
        border: toRem(2) solid $danger;
        @include set-typography-type($text-regular-bold-16);
        margin-bottom: toRem(12);
        text-align: center;
    }

    &__timer {
        background-color: $test-widget-timer-bg-color;
        border-radius: toRem(4);
    }

    &__nav {
        @include media-breakpoint-up(md) {
            padding-bottom: toRem(8);
            border-bottom: toRem(1) solid $primary-var;
        }

        &__title {
            padding-bottom: toRem(8);
            margin-bottom: toRem(12);
            border-bottom: toRem(1) solid $primary-var;

            @include media-breakpoint-up(md) {
                margin: 0;
                padding-bottom: 0;
                border-bottom: none;
            }

            span {
                color: $primary-var;
            }
        }

        &__navigation {
            &__item {
                color: $primary-var;
                &.separator {
                    border-right: toRem(1) solid $test-widget-nav-border-color;
                    padding-right: toRem(8);
                    margin-right: toRem(8);
                    border-radius: 0;
                }
            }
        }
    }

    &__card {
        background-color: $test-widget-card-bg-color;
    }

    &__flag-question {
        .icon-component {
            margin-right: toRem(8);
            svg {
                height: toRem(20);
                width: toRem(20);
            }
        }
    }

    &__note {
        border: toRem(1) solid $test-widget-note-border-color;
        padding: toRem(16);
        background-color: $white;

        &__text {
            .icon-component {
                margin-right: toRem(8);
                svg {
                    height: toRem(20);
                    width: toRem(20);
                }
            }
        }
    }

    &__order-list {
        &__item {
            padding: toRem(8);
            border: toRem(1) solid $test-widget-order-list-item-border-color;
            margin-bottom: toRem(12);

            &.selected {
                background-color: $test-widget-order-list-selected-item-bg-color;
                border: toRem(1) solid $test-widget-order-list-selected-item-border-color;
            }

            p {
                margin-bottom: 0;
            }
            &--readonly {
                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__actions {
        &__btn {
            border-radius: 0;
        }
    }

    &__choice {
        &--correct {
            background-color: $light-gray;
        }

        .custom-control-input:checked ~ .custom-control-label {
            &:before {
                background-color: $primary-var;
                border-color: $primary-var;
            }

            &.error {
                color: $danger;

                &::before {
                    border-color: $danger;
                    background-color: $danger;
                }

                &::after {
                    border-color: $danger;
                }
            }

            &.success {
                color: $success;

                &::before {
                    border-color: $success;
                    background-color: $success;
                }

                &::after {
                    border-color: $success;
                }
            }
        }

        .custom-control-label {
            display: flex;
            gap: toRem(4);
            @include set-typography-type($text-small);

            p {
                margin-bottom: 0;
            }

            &::before {
                border-color: $primary-var;
            }
        }
    }

    &__book-cover {
        display: flex;
        justify-content: center;
        img {
            max-width: 100%;
        }
    }

    &__related-articles {
        &__title {
            border-bottom: toRem(1) solid $primary-var;
            padding-bottom: toRem(6);
            @include set-typography-type($text-large-bold);
        }

        &__list {
            li {
                @include set-typography-type($text-regular);
                border-bottom: toRem(1) solid $gray;
                padding: toRem(8) 0;

                &:last-child {
                    border: none;
                }

                a {
                    color: $deep-gray;
                }
            }
        }
    }

    &__question-body {
        h1 {
            color: $primary-var;
            @include set-typography-type($text-regular-bold);
        }

        & > .container {
            padding: 0;
        }
    }
}

.exam-page {
    &.container {
        max-width: toRem(1440);
    }
}
