.dropdown-toggle,
.tabs-toggle {
    &:before {
        background: var(--primary);
    }
}

.nav-tabs {
    border-bottom-color: $light-gray;
}

.branded-tabs {
    // Branded tabs for Access Entitlements Widget
    .access-widget &,
    .favorites & {
        flex-wrap: nowrap;
        margin-bottom: toRem(24);
        border-bottom-color: $gray;

        .nav-item {
            flex: 1;
        }

        .nav-link {
            display: flex;
            align-items: center;
            justify-content: center;
            @include set-typography-type($text-mini-bold-uppercase);
            padding: toRem(16) toRem(12);
            text-transform: none;
        }
    }

    // Branded tabs for Favorites Widget
    .favorites & {
        margin-bottom: toRem(32);

        .nav-link {
            height: 100%;
        }
    }

    .nav-link {
        text-transform: none;

        &--small {
            text-transform: none;
        }

        &--mobile-resize {
            text-transform: none;
        }
    }
}
