.advanced-search {
    $this: &;

    .form-control {
        color: $advanced-search-input-placeholder-color;
    }

    &__autocomplete {
        & > .form-group {
            margin-bottom: toRem(32);
        }

        .autoComplete {
            &--tags {
                padding: toRem(6);
                overflow-y: auto;
                max-height: toRem(400);

                .autoComplete__item {
                    margin: toRem(4) 0;
                }
            }
        }
    }

    &__range {
        .form-group {
            & > .form-group {
                margin-bottom: toRem(32);
            }
        }

        &__field--static {
            .advanced-search__range__select {
                width: toRem(160);

                @include media-breakpoint-up(md) {
                    width: toRem(210);
                }
            }
        }
    }

    &__buttons {
        .btn {
            padding: toRem(6) toRem(16);
        }
    }

    &__timesheet {
        .form-group {
            & > .form-row {
                & > .advanced-search__select__wrapper {
                    &:first-of-type {
                        margin-right: toRem(65);
                    }
                }
            }
        }

        .advanced-search__select__wrapper {
            max-width: max-content;
        }

        .advanced-search__range__select {
            width: toRem(98);

            @include media-breakpoint-up(md) {
                width: toRem(100);
            }
        }
    }
}
