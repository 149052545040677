.journal-covers-container,
.podcasts-covers-container {
    display: flex;
    column-gap: toRem(35);
    overflow-x: auto;

    @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: max-content max-content max-content;
        column-gap: normal;
        justify-content: space-between;
        row-gap: toRem(35);
    }

    @include media-breakpoint-up(lg) {
        display: flex;
    }
}

.podcasts-covers-container {
    column-gap: toRem(16);

    @include media-breakpoint-up(md) {
        row-gap: toRem(35);
    }
}
