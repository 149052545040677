.profile-menu {
    &__sidebar {
        .sidebar__content {
            @include media-breakpoint-up(xl) {
                padding-right: 0;
            }
        }
    }

    #profileMenuDialog {
        p {
            white-space: normal;
        }
    }

    &__item {
        &__redirect-btn {
            all: unset;
            color: $deep-gray;

            &:hover {
                text-decoration: underline;
                color: $primary;
            }

            @include set-typography-type($text-small-bold-uppercase);
            @include media-breakpoint-up(sm) {
                display: block;
                color: $dark-gray;
                @include set-typography-type($text-mini-bold-uppercase);
            }
        }
    }

    &__link {
        white-space: normal;
    }
}
