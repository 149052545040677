.past-issue {
    $this: &;

    &-col {
        @include media-breakpoint-up(md) {
            max-width: 32.333%;
        }
    }

    .view-issue-btn {
        @include set-typography-type($text-mini-bold-uppercase);
        border-radius: toRem(70);
        border: toRem(1) solid $white;
        height: toRem(24);
        width: toRem(70);
    }

    &__content {
        &__item {
            &--cover-date {
                @include set-typography-type($text-regular-bold-16);
                color: $deep-gray;
                text-transform: capitalize;
            }
        }

        &__text--title {
            @include set-typography-type($text-regular-bold-16);
            color: $deep-gray;
        }
    }
}
