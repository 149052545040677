.email-alerts {
    &__links-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        span {
            a {
                color: $link-colors;
                text-decoration: underline;
            }
        }
    }

    &__sign-up-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: toRem(44);

        @include media-breakpoint-up(md) {
            width: auto;
            min-height: toRem(34);
        }
    }
}
