.custom-page {
    h4,
    h5,
    h6 {
        color: $primary-var;
        margin-bottom: toRem(16);

        a {
            color: $primary-var;
        }
    }

    h4 {
        @include set-typography-type($text-regular-bold);

        a {
            @include set-typography-type($text-regular-bold);
        }
    }

    h5 {
        @include set-typography-type($text-regular-bold-16);

        a {
            @include set-typography-type($text-regular-bold-16);
        }
    }

    h6 {
        @include set-typography-type($text-small-bold);

        a {
            @include set-typography-type($text-small-bold);
        }

        &.init-color {
            color: $deep-gray;
        }
    }

    p,
    a {
        @include set-typography-type($text-small);
    }

    &.page-top-margin {
        margin-top: toRem(30);
    }

    .page-top-margin {
        margin-top: toRem(30);
    }

    .margin-top-15 {
        margin-top: toRem(15);
    }

    .margin-bottom-15 {
        margin-bottom: toRem(15);
    }

    .border-bottom {
        padding-bottom: toRem(10);
        border-bottom: solid $light-gray toRem(2) !important; // to overwrite bootstrap
        width: 100%;
    }

    .row.separator {
        margin-top: toRem(30);
    }

    .theme-note {
        background-color: $secondary-var;
        border-left: toRem(5) solid $primary-var;
        padding: toRem(10) toRem(16);
        color: $deep-gray;
        @include set-typography-type($text-small);

        &.red-note {
            @extend .foc;
        }
    }

    .card--shadow {
        box-shadow: 0 0.313rem 0.313rem rgba(0, 0, 0, 0.14);
        background-color: $white;
        padding: toRem(10);
        margin-top: toRem(25);
        @include set-typography-type($text-small);
    }

    .card-small {
        margin-top: toRem(15);
    }

    [class*='card'] ~ [class*='card'] {
        margin-top: toRem(16);
    }

    ul {
        &.guidelinesTOC {
            padding: 0;
            margin: 0;
            list-style: none;

            & > li {
                margin-bottom: toRem(24);

                ul {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                }
            }
        }
    }

    .center {
        text-align: center;
    }

    .slider__container {
        margin: toRem(30) 0;

        .nav-link {
            @include set-typography-type($text-mini-bold);
            padding: toRem(16) toRem(8);
        }
    }

    .slideShow--gray-background {
        background-color: $very-light-gray;
        box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.2);
    }

    .cmeEntry {
        margin-bottom: toRem(20);
    }

    .books-container {
        .cover-image.reversed {
            padding-top: toRem(20);
            align-items: center;
            max-width: 100%;

            .cover-image {
                &__image {
                    max-height: toRem(250);
                    max-width: toRem(176);

                    img {
                        height: 100%;
                        max-height: toRem(250);
                        width: 100%;
                        max-width: toRem(176);
                    }
                }
            }
        }
    }

    .text-onDark {
        color: $white;

        h5 {
            color: $white;
        }

        ul a,
        p a {
            color: $white;
        }

        .slide-image > img {
            width: 100%;
            max-height: toRem(450);
            height: toRem(450);
        }
    }

    .meta-wrapper {
        background-color: $very-light-gray;
        height: toRem(214);
        position: absolute;
        width: 100%;
        z-index: $meta-wrapper-z-index;
    }

    .image__slider .slideshow {
        padding: 0 toRem(28);
    }

    .image__slider .dark-transparent-bg {
        position: absolute;
        bottom: 0;
        padding: toRem(10) toRem(15);
        min-height: toRem(180);
        width: 100%;
    }

    .dark-transparent-bg {
        background-color: rgba(0, 0, 0, 0.55);
        color: $white;
    }

    // psy library
    .new-book-tag {
        @extend .foc;
        @include set-typography-type($text-mini-bold);
        color: $primary-var;
    }

    // patient education
    .patient-education {
        &__book {
            margin-bottom: toRem(20);
            padding-bottom: toRem(20);
            border-bottom: solid $gray toRem(2);
        }
    }

    // education
    a.btn__edu {
        background-color: $white;
        color: $primary-var;
        border-radius: toRem(3);
        border: toRem(0.1) solid $primary-var;
        display: flex;
        align-items: center;
        gap: toRem(4);
        margin-bottom: toRem(2);
        padding: toRem(8) toRem(16);
        font-size: toRem(14);
        margin-right: toRem(4);

        i {
            font-size: toRem(18);
        }
    }

    // schizophernia
    &.schizo {
        .slider__container {
            padding-top: toRem(15);
            margin: 0;
        }

        .swiper-wrapper {
            padding-bottom: toRem(40);
        }

        .swiper-slide {
            max-height: toRem(450);
            height: toRem(450);
        }

        .browse-journal__item {
            min-height: toRem(150);
            padding-bottom: 0;
        }
    }

    .top-content__block {
        margin-top: toRem(35);

        h1 {
            @include set-typography-type($text-mini-bold);
        }

        .creative-work {
            border-bottom: none;
            border-top: toRem(2) solid $gray;
            padding-top: toRem(10);
            margin-bottom: 0;
        }

        .top-content-alt {
            margin-bottom: toRem(20);
            .creative-work__title {
                color: $black;
                margin-bottom: toRem(5);
            }

            .creative-work {
                padding-bottom: toRem(10);
            }
        }
    }

    .rlist {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .browse-journal__item {
        .item__image {
            width: 35%;
            float: left;
            padding-right: 1.25rem;
        }

        .item__date {
            &:before {
                content: ' |';
            }
        }

        .item__body {
            display: inline-block;
            width: 55%;
        }

        .meta__title {
            margin-bottom: 0.938rem;

            a {
                color: $black;
                @include set-typography-type($text-small-bold);
            }
        }

        .meta__details {
            @include set-typography-type($text-small-bold);
            a {
                color: $black;
                @include set-typography-type($text-small-bold);
            }
        }
    }

    a.btn__schizo {
        background-color: $primary;
        color: $white;
        border-radius: toRem(3);
        display: inline-block;
        margin-bottom: toRem(2);
        padding: toRem(10) toRem(24);
        font-size: toRem(14);
    }

    .advertisement {
        margin-top: toRem(16);
        iframe {
            max-width: 100%;

            @include media-breakpoint-up(xl) {
                max-width: initial;
            }
        }
    }
}

.help-center {
    &-list__sidebar {
        @extend .d-sm-block;
        width: 100%;
        flex: auto;
        overflow: hidden auto;

        background-color: $secondary;

        &:not(.show) {
            transform: translateX(100%);
        }

        @include media-breakpoint-up(sm) {
            position: static;
            opacity: 1;
            overflow: visible;
            visibility: visible;
            transform: none;
            min-width: 0;

            border-left: toRem(5) solid $primary;

            &:not(.show) {
                transform: translateX(0);
            }
        }

        &__content {
            padding: toRem(10) toRem(16);
            white-space: wrap;
        }
    }

    &-list {
        a {
            color: $deep-gray;
        }

        li:hover {
            background-color: $dark-gray;
            cursor: pointer;

            a {
                color: $white;
            }
        }
    }
}
