.psych-news-topic {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__image {
        width: 100%;

        @include media-breakpoint-up(md) {
            max-width: toRem(290);
            height: toRem(240);
        }

        @include media-breakpoint-up(lg) {
            max-width: toRem(384);
            height: toRem(240);
        }
    }
}

.psych-news-topic-wrapper {
    .list-unstyled {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: toRem(32);

        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: toRem(32);
        }

        @include media-breakpoint-up(lg) {
            row-gap: toRem(48);
        }
    }
}
