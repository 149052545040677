.test-evaluation {
    &__title {
        @include set-typography-type($text-large-bold);
        background-color: $primary-var;
        color: $white;
    }

    &__header-banner {
        @include set-typography-type($text-large-bold);
        h2 {
            @include set-typography-type($text-large-bold);
        }

        background-color: $primary-var;
        color: $white;
    }

    &__options-details {
        white-space: wrap;
    }

    &__sidebar {
        @extend .d-sm-block;
        width: 100%;
        flex: auto;
        overflow: hidden auto;

        &:not(.show) {
            transform: translateX(100%);
        }

        @include media-breakpoint-up(lg) {
            position: static;
            opacity: 1;
            overflow: visible;
            visibility: visible;
            transform: none;
            min-width: 0;
            height: max-content;

            &:not(.show) {
                transform: translateX(0);
            }
        }
    }

    &__form {
        padding: toRem(16);
        background-color: $very-light-gray;

        .required:after {
            content: ' *';
            color: $primary-var;
        }

        &__question {
            margin-bottom: toRem(24);

            &__error {
                color: $danger;

                p {
                    margin-top: toRem(8);
                }
            }

            &__title {
                @include set-typography-type($text-regular-bold);
                margin: toRem(2) 0 toRem(16);
            }

            &__options {
                .form-group {
                    margin-bottom: toRem(16);

                    &:last-child {
                        margin-bottom: toRem(4);
                    }

                    textarea {
                        border: 0;
                        padding: toRem(8) toRem(16);
                        width: 100%;
                    }
                }

                &.radio--btn__options {
                    display: flex;
                    flex-wrap: wrap;
                    row-gap: toRem(12);
                    column-gap: toRem(8);
                    .form-group {
                        margin-bottom: 0;
                        display: inline-block;
                    }
                }
            }
        }

        .custom-control-label {
            text-transform: none;

            &::before {
                border-color: $primary-var;
            }
        }

        .custom-control-input {
            &:checked {
                & ~ .custom-control-label {
                    &::before {
                        background-color: $primary-var;
                        border-color: $primary-var;
                    }
                }
            }
        }

        .disciplin-text-input {
            width: 100%;
            max-width: toRem(400);
            background: $white;
            border: none;
            padding: toRem(14);
            margin-top: toRem(14);
            display: block;
        }

        .radio--btn {
            margin-bottom: 0;
            input {
                appearance: none;
                position: absolute;
                width: toRem(1);
                height: toRem(1);
                padding: 0;
                overflow: hidden;
                clip: rect(0, 0, 0, 0);
                white-space: nowrap;
                border: 0;

                &:checked {
                    & ~ .label-text {
                        background-color: $primary-var;
                        color: $white;
                    }
                }
            }

            .label-text {
                border: toRem(1) solid $primary-var;
                color: $primary-var;
                padding: toRem(5) toRem(11);
                background: transparent;
                display: inline-block;
                transition: none;
            }
        }
    }
}
