.text-primary-var {
    color: var(--primary);

    &:hover {
        color: var(--primary);
    }
}

.text-black {
    color: $black;
}

.bg-primary-var {
    background-color: var(--primary);
}

.text-secondary-var {
    color: var(--primary);
}

.text-link-color {
    color: $link-color;
    cursor: pointer;
}

.ajp-rj {
    @extend .ajp-Rj;
}

.focus {
    @extend .foc;
}

.ebooks {
    --primary: #{$ebook-color};
}

.adv-col {
    @include media-breakpoint-up(md) {
        &.col-md-8 {
            flex: 0 0 55%;
            max-width: 55%;
        }
        &.col-md-4 {
            flex: 0 0 45%;
            max-width: 45%;
        }
    }

    @media only screen and (min-width: 900px) { /*stylelint-disable-line*/
        &.col-lg-8 {
            flex: 0 0 62%;
            max-width: 62%;
        }
        &.col-lg-4 {
            flex: 0 0 38%;
            max-width: 38%;
        }
    }
    @include media-breakpoint-up(lg) {
        &.col-lg-8 {
            flex: 0 0 63%;
            max-width: 63%;
        }
        &.col-lg-4 {
            flex: 0 0 37%;
            max-width: 37%;
        }
    }

    @include media-breakpoint-up(xl) {
        &.col-xl-8 {
            flex: 0 0 67%;
            max-width: 67%;
        }
        &.col-xl-4 {
            flex: 0 0 33%;
            max-width: 33%;
        }
    }
}

img {
    max-width: 100%;
}

.citationFormats {
    .formats-select-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: toRem(16);

        .form-control {
            font-size: toRem(12);
        }
    }

    .copyToClipBoard {
        margin-top: toRem(12);
    }
}

.books-page {
    &__description {
        a {
            text-decoration: underline;
            text-underline-offset: toRem(4);
        }
    }
}
