.login-bar {
    &.hidden {
        display: none;
    }

    .sign-in-link {
        &:hover {
            text-decoration: none;
        }
    }

    #profileMenu {
        color: $primary;
        .icon-component {
            margin-right: toRem(8);
        }
    }

    .dropdown {
        &-menu {
            @include media-breakpoint-down(xl) {
                right: 0;
                left: initial;
            }
        }

        &-toggle::before {
            background-color: $primary;
        }

        &-item {
            &:hover {
                background-color: $primary;
                color: $white;
            }
        }
    }

    position: absolute;
    right: toRem(85);
    top: toRem(5);
    z-index: map-get($map: $z-index-map-app, $key: 'login-bar-icon');

    .hero-header & {
        top: toRem(7);
    }

    @include media-breakpoint-up(md) {
        top: toRem(11);

        .hero-header & {
            top: toRem(13);
        }
    }

    @include media-breakpoint-up(lg) {
        top: toRem(23);

        .hero-header & {
            top: toRem(23);
        }
    }

    @include media-breakpoint-up(xl) {
        position: initial;
    }

    .nav-link {
        padding: 0.5rem 0;

        &:not(:last-child) {
            margin-right: 0;

            &:after {
                display: none;
            }
        }
    }

    .login-link-icon {
        color: $darker-gray;

        &.loggen-in {
            color: $primary;
        }
        .icon-component {
            font-size: toRem(20);
        }
    }
}
