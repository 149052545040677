.co-branding {
    h4 {
        @include set-typography-type($text-regular-bold);
    }

    .banner-text {
        padding-top: 0 !important; // to overwrite the util classes
    }

    textarea::-webkit-resizer {
        border-width: toRem(15);
        border-style: solid;
        border-color: transparent $dark-gray $dark-gray transparent;
    }

    .input-group {
        width: auto;

        .form-group {
            margin-bottom: toRem(8);
        }
    }

    .btn {
        width: 100%;
        min-height: toRem(44);

        @include media-breakpoint-up(md) {
            width: auto;
            min-height: toRem(34);
        }

        @include media-breakpoint-up(lg) {
            float: right;
        }
    }
}
