.education-access-entitlement {
    .table {
        &__expandable {
            th {
                border-top: 0;
                background-color: $primary;
                color: $white;

                &:first-child {
                    padding-left: toRem(16);
                }

                &:not(:first-child) {
                    text-align: center;
                }
            }

            tr {
                background-color: $very-light-gray;
            }

            td {
                color: $primary;
                border-right: toRem(1) solid $gray;
                border-top: none;

                &:first-child {
                    padding-left: toRem(16);
                }

                &:not(:first-child) {
                    text-align: center;
                }

                &:last-child {
                    border-right: none;
                }

                a {
                    color: $primary;
                }
            }

            @include media-breakpoint-down($expandable-table-breakpoint) {
                tr {
                    padding-top: toRem(8);
                    padding-left: toRem(8);
                    background-color: $very-light-gray;
                }

                td {
                    border-right: none;

                    a {
                        color: $primary;
                    }

                    &:not(:first-child) {
                        margin-top: toRem(2);
                        text-align: left;
                    }

                    &:not(.d-none):before {
                        @include set-typography-type($text-small-bold-uppercase);
                        color: $primary;
                        display: inline;
                        margin: 0 toRem(5) 0 0;
                    }

                    &:first-child {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    &__empty {
        border-radius: toRem(3);
        background-color: $secondary;
        color: $primary;
    }

    &__note {
        display: flex;
        flex-direction: column;
        gap: toRem(8);
        border-radius: toRem(3);
        background: $education-access-entitlement-note-bg-color;
        color: $black;
        padding: toRem(24);
        margin-bottom: toRem(16);
    }
}

.dashboard-widget.education-access-entitlement {
    .table {
        &__expandable {
            th {
                border-bottom: toRem(1) solid $primary;
                background-color: $white;
                color: $deep-gray;

                &:first-child {
                    padding-left: 0;
                }
            }

            tr {
                background-color: $white;
            }

            td {
                color: $deep-gray;
                border-right: none;

                &:first-child {
                    padding-left: 0;
                }

                a {
                    color: $primary;
                }
            }

            @include media-breakpoint-down($expandable-table-breakpoint) {
                tr {
                    background-color: $white;
                    padding-left: 0;
                }

                td {
                    color: $deep-gray;

                    a {
                        color: $primary;
                    }
                }
            }
        }
    }
}
