.ip-ranges {
    & > p {
        margin-bottom: toRem(24);
    }

    h4 {
        @include set-typography-type($text-regular-bold);
    }

    .form-control {
        &::placeholder {
            color: $gray;
        }
    }

    &__add-range {
        .form-group {
            margin-bottom: toRem(16);
        }

        button {
            width: 100%;
            min-height: toRem(44);

            @include media-breakpoint-up(sm) {
                width: auto;
                min-height: toRem(34);
            }

            @include media-breakpoint-up(lg) {
                width: toRem(154);
            }
        }

        .ipexamples {
            @include media-breakpoint-up(md) {
                white-space: nowrap;
            }
        }
    }

    &__existing-ranges-section {
        input {
            padding: toRem(8);
            @include set-typography-type($text-small);
        }

        .headings {
            div {
                @include media-breakpoint-up(md) {
                    &:first-child {
                        margin-right: 7.5%;
                    }
                }
            }
        }

        .table-data-row {
            div {
                @include media-breakpoint-up(md) {
                    &:first-child {
                        margin-right: 7.5%;
                    }
                }
            }

            &:last-child {
                div {
                    @include media-breakpoint-up(md) {
                        &:first-child {
                            margin-right: 0;
                        }
                    }
                }

                label {
                    @include set-typography-type($text-mini);
                    text-transform: uppercase;
                    padding-left: toRem(16);

                    @include media-breakpoint-up(md) {
                        padding-left: 0;
                        margin-bottom: toRem(4);
                    }
                }
            }
        }
    }

    &__update-buttons-wrapper {
        display: flex;
        flex-direction: column;
        gap: toRem(8);

        @include media-breakpoint-up(md) {
            flex-direction: row;
            gap: 0;
        }

        .btn {
            width: 100%;
            min-height: toRem(44);

            @include media-breakpoint-up(md) {
                width: auto;
                min-height: toRem(34);
            }
        }
    }

    &__notes {
        a {
            text-decoration: underline;
        }
    }

    .ipexamples {
        .example {
            display: block;
        }
    }
}
