.do-page-banner {
    img {
        width: toRem(120);
        height: toRem(54);
    }

    &-links {
        @extend .d-sm-block;
        width: 100%;
        flex: auto;
        overflow: hidden auto;

        &:not(.show) {
            transform: translateX(100%);
        }

        z-index: map-get($map: $z-index-map-app, $key: 'do-banner-list-sm');

        @include media-breakpoint-up(md) {
            z-index: map-get($map: $z-index-map-app, $key: 'do-banner-list');
        }

        @include media-breakpoint-up(sm) {
            position: static;
            opacity: 1;
            overflow: visible;
            visibility: visible;
            transform: none;
            min-width: 0;

            background-color: transparent;

            &:not(.show) {
                transform: translateX(0);
            }
        }

        &__list {
            padding: 0;
            list-style: none;

            @include media-breakpoint-up(sm) {
                display: flex;
                margin-bottom: 0;
            }

            li {
                padding: toRem(12);
                border-bottom: toRem(1) solid $gray;

                @include media-breakpoint-up(sm) {
                    border-bottom: 0;
                    padding: 0;
                }

                a {
                    color: $deep-gray;
                    @include set-typography-type($text-small-bold);
                    @include media-breakpoint-up(sm) {
                        color: $white;
                    }
                }
            }
        }
    }
}
