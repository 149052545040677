#refworks {
    display: flex;
    flex-direction: column;

    textarea {
        // to overwrite inline stylings
        float: initial !important;
        &::-webkit-resizer {
            border-width: toRem(15);
            border-style: solid;
            border-color: transparent $dark-gray $dark-gray transparent;
        }
        padding: toRem(8);
    }

    input {
        @extend .btn;
        @extend .btn-primary;
        border-radius: 0;
        margin-top: toRem(16);
        padding: toRem(8) toRem(12);
    }
}
