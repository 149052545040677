.journal-article-box {
    box-shadow: $journalArticleBoxShadow;
    color: $black;
    max-width: toRem(384);

    img {
        max-width: toRem(70);
        max-height: toRem(62);
    }

    .top-part {
        border-bottom: toRem(1) solid $dividers;
    }
}
