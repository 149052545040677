.loi {
    &__tab-content {
        min-height: auto;
        .tab-content {
            border-bottom: toRem(1) solid $gray;
        }
    }
    .past-issue {
        &__image {
            box-shadow: $loi-past-issue-box-shadow;
        }

        &__status {
            left: toRem(50);
            top: unset;
            bottom: toRem(25);
            @include set-typography-type($text-mini-bold-uppercase);

            @include media-breakpoint-up(md) {
                left: toRem(-8);
            }
        }
    }

    &__navigation {
        @include media-breakpoint-up(md) {
            padding: 0 toRem(16);
        }

        @include media-breakpoint-up(lg) {
            padding: 0;
        }
    }
}
