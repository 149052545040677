.table {
    &__expandable {
        thead {
            th {
                border-color: $gray;
            }
        }

        tbody {
            tr {
                border-bottom: $expandable-table-tr-border;
            }

            td {
                color: $darker-gray;
                padding: toRem(24) toRem(8);

                @include media-breakpoint-up(lg) {
                    padding: toRem(16) toRem(8);
                }

                border-top: 0;

                a {
                    color: $link-colors;

                    @include media-breakpoint-up(lg) {
                        text-decoration: underline;
                    }
                }

                &:first-child {
                    color: $deep-gray;
                    padding-left: 0;
                }
            }
        }

        th {
            @include set-typography-type($text-small-bold);
            padding: toRem(16) toRem(8) toRem(14);

            &:first-child {
                padding-left: 0;
            }
        }

        @include media-breakpoint-down($expandable-table-breakpoint) {
            td:not(.d-none):before {
                @include set-typography-type($text-mini-bold-uppercase);
                color: $deep-gray;
            }

            tbody {
                td {
                    padding: 0;

                    a {
                        color: $deep-gray;
                    }
                }
            }
        }
    }
}
