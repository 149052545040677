.toc {
    .custom-control-input:checked ~ .custom-control-label {
        &:before {
            border-color: $primary-var;
            background-color: $primary-var;
        }
    }

    .article-actionbar__btns--hide {
        display: flex;
    }

    & + .content-navigation {
        padding: 0;
    }

    .pn &__body {
        section:first-child {
            .to-section {
                margin-top: 0;
            }
        }

        & > div {
            &:first-of-type {
                flex-wrap: wrap;

                @include media-breakpoint-up(lg) {
                    flex-wrap: nowrap;
                }
            }
        }
    }

    .to-section {
        @extend .text-title-1;
        @include decoration(var(--secondary));

        .pn & {
            @extend .text-title-2;
            margin-top: toRem(48);
            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }
    }

    &__book {
        .to-section {
            @extend .text-title-3;
        }

        &__btn-wrapper {
            .btn {
                padding: toRem(8) toRem(16);
                z-index: map-get($map: $z-index-map-app, $key: 'toc-book-btn');

                @include media-breakpoint-up(md) {
                    width: toRem(240);
                }
            }
        }

        .access-icon {
            position: absolute;
            top: toRem(312);
            right: toRem(5);

            @include media-breakpoint-up(md) {
                top: 0;
            }
        }

        .accordion {
            &__toggle {
                all: unset;
                color: $deep-gray;
                @include set-typography-type($text-small-bold-uppercase);
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                &:hover {
                    text-decoration: none;
                }

                .icon-component {
                    font-size: toRem(15);
                }
            }

            &__list {
                &-item {
                    padding: toRem(16) 0 toRem(16) toRem(30);

                    &:first-child {
                        padding-top: 0;
                    }

                    &:last-child {
                        margin-bottom: toRem(16);
                    }
                }
            }

            &__expand-all,
            &__close-all {
                all: unset;
                color: $link-color;
                @include set-typography-type($text-small);
                display: flex;
                align-items: center;
                cursor: pointer;
                & > span:not(.icon-component) {
                    margin-top: toRem(1);
                }

                .icon-component {
                    font-size: toRem(10);
                }
            }
        }

        #toc__book__section {
            scroll-margin-top: toRem(60);

            @include media-breakpoint-up(md) {
                scroll-margin-top: toRem(100);
            }
        }
    }
}

.bits-book {
    .toc {
        &__section {
            margin-bottom: toRem(12);

            .card {
                padding: toRem(12);
            }

            .toc__section {
                margin-bottom: 0;

                .to-section {
                    padding-top: toRem(12);
                    padding-left: toRem(24);
                }

                .card {
                    border-left: toRem(24) solid var(--secondary);
                    border-right: toRem(24) solid var(--secondary);
                }
            }

            .to-section {
                position: sticky;
                cursor: pointer;
                padding: toRem(12);
                background: var(--secondary);
                top: calc(var(--totalHeaderHeight) - toRem(40));
                font-size: toRem(17);
                line-height: 1.5;

                & > h2 {
                    font-size: toRem(17);
                    color: $deep-gray;
                    line-height: 1.5;
                }

                .icon-component {
                    color: $deep-gray;
                }

                span {
                    font-size: toRem(16);
                }

                &::before {
                    display: none;
                }

                & ~ div {
                    .card {
                        border-left: toRem(12) solid var(--secondary);
                        border-right: toRem(12) solid var(--secondary);

                        &:last-of-type {
                            border-bottom: toRem(12) solid var(--secondary);

                            .card-content {
                                border-bottom: 0 !important; // to override the default bootstrap style
                            }
                        }
                    }
                }
            }
        }

        &__book {
            &__btn-wrapper {
                .btn {
                    width: auto;
                    font-size: toRem(10);
                    padding: toRem(2) toRem(14);
                }
            }
        }

        .nav-link {
            font-size: toRem(16);
        }

        .article-title {
            font-size: toRem(18);
        }
    }
}
