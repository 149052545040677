.main-menu,
.publication-menu {
    @include media-breakpoint-down(lg) {
        border-bottom: toRem(1) solid $light-gray;
        box-shadow: 0 toRem(6) toRem(6) 0 rgba(0, 0, 0, 0.09);

        &__nav {
            border-top: none;
        }
    }

    .navbar-nav {
        .nav-item,
        .dropdown-item {
            @include media-breakpoint-down(lg) {
                justify-content: flex-start;
            }
        }

        .nav-item {
            &--quick-search {
                z-index: map-get($map: $z-index-map-app, $key: 'header-quick-search-result');

                .quick-search {
                    @include media-breakpoint-down(xl) {
                        align-items: start;
                    }
                }
            }

            &:not(.nav-item--quick-search) {
                border-bottom: toRem(1) solid $gray;

                @include media-breakpoint-up(xl) {
                    border-bottom: none;
                }

                .nav-link {
                    margin: 0 toRem(16);
                    padding: toRem(16) 0;

                    @include media-breakpoint-up(md) {
                        padding: toRem(24) 0;
                    }

                    @include media-breakpoint-up(xl) {
                        margin: 0;
                        padding: toRem(8) 0;
                        white-space: nowrap;
                    }
                }

                .dropdown {
                    &-toggle {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 0 toRem(16);
                        padding: toRem(16);
                        width: 100%;

                        @include media-breakpoint-up(md) {
                            padding: toRem(24) toRem(16);
                        }

                        @include media-breakpoint-up(xl) {
                            margin: 0;
                            padding: toRem(12) 0;
                        }

                        &::after {
                            font-size: toRem(12);

                            @include media-breakpoint-up(xl) {
                                font-size: toRem(16);
                                margin-left: toRem(12);
                                border-bottom: 0.18rem solid;
                                border-right: 0.18rem solid;
                            }
                        }

                        &::before {
                            background: $primary;
                        }
                    }

                    &-item {
                        @include set-typography-type($text-mini-bold);
                        padding: toRem(16);
                        white-space: normal;

                        @include media-breakpoint-up(md) {
                            padding: toRem(24) toRem(16);
                        }

                        @include media-breakpoint-up(xl) {
                            padding: toRem(12);
                            min-width: toRem(330);
                        }

                        &:hover {
                            background-color: $primary;
                            color: $white;
                        }
                    }

                    &-menu {
                        background-color: $very-light-gray;
                        box-shadow: none;
                        border: none;
                        padding: 0;
                        margin: 0;
                        border-radius: 0;

                        @include media-breakpoint-up(xl) {
                            background-color: $white;
                            border: toRem(1) solid $gray;

                            &.dropdown-menu__left {
                                right: 0;
                            }
                        }
                    }
                }
            }

            .dropdown-submenu {
                min-width: toRem(330);
                & > .dropdown-menu {
                    min-width: toRem(330);

                    .nav-item {
                        border-bottom: 0;

                        a {
                            padding-left: toRem(26);
                            font-weight: 500;
                        }
                    }
                }

                .dropdown-toggle {
                    width: 100%;
                    padding: toRem(16);

                    @include media-breakpoint-up(md) {
                        padding: toRem(24) toRem(16);
                    }

                    @include media-breakpoint-up(xl) {
                        padding: toRem(12);
                    }
                }
            }

            &--quick-search.search-active {
                .quick-search {
                    &__trigger {
                        color: $primary;
                    }

                    &-icon-animated {
                        stroke: $primary;
                    }
                }
            }
        }
    }
}

.publication-menu {
    .navbar-nav {
        .nav-item {
            &:not(.nav-item--quick-search) {
                .dropdown-toggle {
                    margin: 0;
                }
            }
        }
    }
}

.main-menu {
    .navbar-nav {
        & > .nav-item {
            & > .nav-link {
                font-size: toRem(14);
            }

            @include media-breakpoint-up(xl) {
                &--quick-search {
                    padding-left: toRem(56);
                }

                padding-left: toRem(24);

                &:first-child {
                    padding-left: 0;
                }
            }
        }

        .nav-item {
            &.dropdown,
            &.dropdown-submenu {
                flex-direction: column;
                align-items: center;

                @include media-breakpoint-up(xl) {
                    flex-direction: initial;
                    align-items: initial;
                }
            }

            &.dropdown {
                &:hover {
                    & > .nav-link {
                        &::before {
                            @include media-breakpoint-up(xl) {
                                transform: scaleX(1);
                            }
                        }

                        &:after {
                            @include media-breakpoint-up(xl) {
                                transform: $menu-rotate-deg-135;
                            }
                        }
                    }
                }
            }

            &:hover {
                & > .dropdown-menu {
                    @include media-breakpoint-up(xl) {
                        display: block;
                    }
                }
            }
        }
    }

    .dropdown {
        &-menu {
            left: initial;
            border: $menu-dropdown-border;
            border-radius: $menu-dropdown-border-radius;

            @include media-breakpoint-up(xl) {
                border: initial;
                border-radius: initial;
            }
        }

        &-submenu {
            @include media-breakpoint-up(xl) {
                position: relative;
            }

            & > .nav-link {
                color: $deep-gray;

                &:hover,
                &:focus {
                    color: $dropdown-link-color;
                }

                &::after {
                    @include media-breakpoint-up(xl) {
                        transform: $menu-rotate-deg-45;
                    }
                }

                @include media-breakpoint-up(xl) {
                    padding: $menu-dropdown-link-xl-padding;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;
                    font-size: $menu-dropdown-link-xl-font-size;
                    font-weight: $menu-dropdown-link-xl-font-weight;

                    &:hover {
                        background-color: $primary;
                        color: $white;
                    }
                }
            }

            &.show {
                & > .nav-link {
                    color: $deep-gray;
                    &::after {
                        transform: $menu-rotate-deg-135;

                        @include media-breakpoint-up(xl) {
                            transform: $menu-rotate-deg-45;
                        }
                    }
                }
            }

            & > .dropdown-menu {
                @include media-breakpoint-up(xl) {
                    top: 0;
                    left: toRem(330);
                }
            }
        }
    }
}

.main-navbar {
    z-index: $main-navbar-z-index;
    &.navbar {
        padding: 0;
    }

    @include media-breakpoint-up(xl) {
        border-bottom: toRem(1) solid $gray;
    }

    .navbar-brand {
        img {
            max-width: toRem(107);

            @include media-breakpoint-up(lg) {
                max-width: toRem(200);
            }
        }
    }

    .navbar-in {
        padding: toRem(2) 0;

        @include media-breakpoint-up(md) {
            padding: toRem(8) 0;
        }

        @include media-breakpoint-up(xl) {
            padding: 0;
        }
    }

    &.navbar-expanded {
        @include media-breakpoint-down(md) {
            max-height: calc(100vh - 2.45rem);
        }

        @include media-breakpoint-up(md) {
            max-height: calc(100vh - 8.75rem);
        }
    }
}

.navbar-toggler {
    &__box {
        width: toRem(33);
    }

    .navbar-expanded & {
        display: flex;
        align-items: center;
        gap: toRem(8);
    }

    .navbar-toggler__box {
        &.open {
            width: toRem(27);
            height: toRem(26);
            .navbar-toggler__inner,
            .navbar-toggler__inner::after {
                height: toRem(3);
            }
        }
    }

    .navbar-toggler__close-text {
        display: block;
        &.hidden {
            display: none;
        }
    }
}

.navbar-in {
    border-bottom: toRem(1) solid $gray;

    @include media-breakpoint-up(xl) {
        border: none;
    }
}

.nav-item {
    &--quick-search {
        padding: 0;
        margin-bottom: toRem(8);

        @include media-breakpoint-up(lg) {
            padding: toRem(24) 0;
            margin-bottom: 0;
        }

        @include media-breakpoint-up(xl) {
            padding-left: toRem(56);
        }

        .show & {
            background-color: $white;
        }

        .quick-search {
            .form-control {
                border: none;
                border-bottom: toRem(2) solid $primary;
                padding: toRem(19) 0;

                &:focus {
                    box-shadow: none;
                }

                &::placeholder {
                    font-style: italic;
                    color: $quick-search-single-placeholder-color;
                    text-transform: capitalize;
                    @include set-typography-type($text-small-italics);
                }
            }

            .quick-search__btn {
                border-bottom: toRem(2) solid $primary;
                color: $primary;

                .icon-component {
                    color: $primary;
                }
            }
        }
    }
}

.navbar-light {
    background-color: $white;

    .navbar-toggler {
        border: none;
        padding: 0.5rem 0.125rem;
    }

    .quick-search {
        border-bottom: none;
        &__links {
            &.collapsing {
                all: unset;
            }

            .btn {
                @include media-breakpoint-up(xl) {
                    background-color: $white;
                    color: $deep-gray;
                    padding: toRem(7) toRem(16);
                    box-shadow: $qs-advanced-search-btn-box-shadow;
                }
            }
        }
    }

    .navbar-nav {
        .dropdown.show {
            .nav-link {
                &:focus {
                    color: $primary;
                }

                @include media-breakpoint-up(xl) {
                    color: $deep-gray;
                }
            }
        }
    }
}

header {
    &.main-header.article-header {
        transform: initial;
    }
}

.main-header {
    position: sticky;
    width: 100%;
    z-index: map-get($map: $z-index-map-app, $key: 'main-header');

    &.article-header {
        position: static;

        @include media-breakpoint-up(xl) {
            position: relative;
        }

        & + .content {
            header[data-extent='frontmatter'] {
                @include media-breakpoint-up(xl) {
                    z-index: map-get($map: $z-index-map-app, $key: 'article-header');
                }
            }
        }
    }
}

.header-ad {
    &-container {
        display: none;
        @include media-breakpoint-up(sm) {
            display: block;
        }

        background-color: $deep-gray;
        position: sticky;
        width: 100%;
        z-index: map-get($map: $z-index-map-app, $key: 'header-ad-placeholder');
        top: 0;
        img.img_ad {
            max-width: toRem(490);
            @include media-breakpoint-up(lg) {
                max-width: toRem(728);
            }
        }
    }

    iframe {
        max-width: 100%;
    }

    position: relative;
    &__close {
        all: unset;
        position: absolute;
        top: toRem(5);
        right: toRem(5);
        color: $black;
        font-size: toRem(20);

        &:hover {
            color: $dark-gray;
        }
    }
}
