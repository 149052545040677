.pn__links__boxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: toRem(16);
    row-gap: toRem(32);

    @media only screen and (min-width: 900px) { /*stylelint-disable-line*/
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: toRem(32);
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: toRem(32);
    }

    li {
        border-radius: toRem(10);
        border: toRem(1) solid $very-light-gray;
        background: $white;
        box-shadow: $pn-link-box-show;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: toRem(156);

        @include media-breakpoint-up(md) {
            max-width: toRem(176);
        }

        @include media-breakpoint-up(lg) {
            max-width: initial;
        }
        a {
            color: $deep-gray;
            text-align: center;
            font-size: toRem(17);
            max-width: toRem(108);
            word-break: break-word;

            @include media-breakpoint-up(md) {
                max-width: toRem(128);
            }

            @include media-breakpoint-up(lg) {
                font-size: toRem(18);
                max-width: initial;
                word-break: initial;
            }
        }

        &:hover {
            background-color: $primary-var;
            border-color: $primary-var;
            cursor: pointer;

            a {
                color: $white;
                text-decoration: none;
            }
        }
    }
}
