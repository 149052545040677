$text-regular-bold-16: 'regularBold16';
$text-title-4-lato: 'title4-Lato';

.main-title--decorated {
    &::before {
        max-width: 100%;
        min-width: toRem(104);
    }
}

.ebook {
    --primary: #{$ebook-color};
    --primary-darken-7: #{rgba($ebook-color, 0.9)};
}

.psychotherapy {
    --primary: #{$psychotherapy-color};
    --primary-darken-7: #{rgba($psychotherapy-color, 0.9)};
}

.text-regular-bold-16 {
    @include set-typography-type($text-regular-bold-16);
}
