.cover-image {
    $this: &;

    &.reversed {
        max-width: toRem(240);
        a {
            &:focus {
                img {
                    transform: translate(toRem(8), toRem(-8));
                }
            }
        }
        &.cover-image__author {
            width: 100%;
            max-width: 100%;
            margin-bottom: toRem(16);

            #{$this}__image {
                max-width: toRem(230);
                img {
                    height: auto;
                    max-height: toRem(300);
                    max-width: 100%;
                }
                &::after {
                    display: none;
                }
            }

            #{$this}__details {
                .ebook-library & {
                    text-align: center;
                }
                .book-meta {
                    @include set-typography-type($text-small-bold);
                    text-align: left;
                    color: $black;

                    .ebook-library & {
                        text-align: center;
                    }
                }
            }

            .list-inline {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    color: $dark-gray;
                    a {
                        color: $dark-gray;
                        @include set-typography-type($text-small);
                    }
                }
            }
        }

        .books-page & {
            max-width: toRem(132);

            @include media-breakpoint-up(md) {
                max-width: toRem(150);
            }
        }

        .issue-sidebar__content & {
            .pn & {
                max-width: toRem(200);
            }
            max-width: toRem(170);

            @include media-breakpoint-up(md) {
                max-width: toRem(200);
            }
        }

        .journal-covers-container & {
            max-width: toRem(176);
        }

        .podcasts-covers-container & {
            max-width: toRem(150);

            @include media-breakpoint-up(md) {
                max-width: toRem(176);
            }
        }

        #{$this}__image {
            img {
                height: toRem(195);
                max-width: toRem(240);

                .issue-sidebar__content & {
                    height: auto;
                }

                .journal-covers-container & {
                    width: toRem(176);
                    max-width: toRem(176);
                    height: auto;
                }

                .podcasts-covers-container & {
                    width: toRem(150);
                    max-width: toRem(150);
                    height: auto;

                    @include media-breakpoint-up(md) {
                        width: toRem(176);
                        max-width: toRem(176);
                    }
                }

                .books-page & {
                    @include media-breakpoint-up(md) {
                        height: auto;
                        max-width: toRem(150);
                    }
                }

                @include media-breakpoint-up(md) {
                    height: toRem(365);
                    width: toRem(240);

                    .issue-sidebar__content & {
                        max-width: toRem(280);
                        height: auto;
                        width: toRem(200);
                    }
                }
            }
        }

        #{$this}__details {
            .book-meta {
                @include set-typography-type($text-regular-bold-16);
                text-align: center;
                color: $black;
            }
        }
    }

    &__book-toc {
        &.reversed {
            .meta-tags {
                gap: toRem(8);
            }

            .meta-tag {
                @include set-typography-type($text-micro-uppercase);
                color: $deep-gray;
                border-radius: toRem(28);
                padding: toRem(2) toRem(12);
                background-color: $secondary;
            }

            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: initial;

            #{$this}__image {
                align-self: center;
                max-width: toRem(200);
                margin-bottom: toRem(24);

                @include media-breakpoint-up(md) {
                    height: toRem(295);
                }

                &::after {
                    display: none;
                }

                img {
                    height: toRem(285);
                    max-width: toRem(200);
                }
            }

            @include media-breakpoint-up(lg) {
                flex-direction: row;

                #{$this}__image {
                    align-self: initial;
                    max-width: toRem(208);
                    margin-bottom: 0;
                    margin-right: toRem(24);

                    &::after {
                        display: initial;
                    }

                    img {
                        height: toRem(295);
                        max-width: toRem(208);
                    }
                }
            }
        }

        .cover-image {
            &__details {
                word-wrap: break-word;

                @media only screen and (min-width: 900px) { /*stylelint-disable-line*/
                    max-width: initial;
                    width: auto;
                }

                &__book-category {
                    padding: toRem(2) toRem(12);
                }

                &__authors {
                    .edit-by {
                        white-space: nowrap;
                        margin-right: toRem(4);
                    }

                    .list-inline-item {
                        color: $dark-gray;

                        a {
                            color: $dark-gray;
                        }
                    }
                }

                &__exam-wrapper {
                    .btn {
                        padding: toRem(8) toRem(16);

                        @include media-breakpoint-up(md) {
                            width: toRem(240);
                        }
                    }
                }
            }
        }
    }

    &__dsm {
        &.reversed {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: initial;

            #{$this}__image {
                align-self: center;
                max-width: toRem(200);
                margin-bottom: toRem(32);

                @include media-breakpoint-up(md) {
                    height: toRem(290);
                }

                @include media-breakpoint-up(lg) {
                    height: toRem(399);
                }

                &::after {
                    display: none;
                }

                img {
                    height: toRem(285);
                    max-width: toRem(200);
                }
            }

            @media only screen and (min-width: 950px) { /*stylelint-disable-line*/
                flex-direction: row;

                #{$this}__image {
                    align-self: initial;
                    max-width: toRem(203);
                    margin-bottom: 0;
                    margin-right: toRem(32);

                    &::after {
                        display: none;
                    }

                    img {
                        height: toRem(290);
                        max-width: toRem(203);
                    }
                }
            }

            @media only screen and (min-width: 1060px) { /*stylelint-disable-line*/
                #{$this}__image {
                    max-width: toRem(279);

                    img {
                        height: toRem(399);
                        max-width: toRem(279);
                        width: toRem(279);
                    }

                    &::after {
                        display: initial;
                    }
                }
            }
        }

        .cover-image {
            &__details {
                word-wrap: break-word;
            }
        }
    }

    &__custom {
        &.reversed {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: initial;

            #{$this}__image {
                align-self: center;
                max-width: toRem(226);
                margin-bottom: toRem(24);

                &::after {
                    display: none;
                }

                img {
                    height: toRem(300);
                    max-width: toRem(226);
                }
            }

            @media only screen and (min-width: 950px) { /*stylelint-disable-line*/
                flex-direction: row;

                #{$this}__image {
                    align-self: initial;
                    margin-bottom: 0;
                    margin-right: toRem(24);

                    &::after {
                        display: none;
                    }
                }
            }

            @media only screen and (min-width: 1060px) { /*stylelint-disable-line*/
                #{$this}__image {
                    &::after {
                        display: initial;
                    }
                }
            }
        }

        .cover-image {
            &__details {
                word-wrap: break-word;
            }
        }
    }
}

.cover-images-container {
    overflow-x: auto;
    gap: toRem(16);

    .books-page & {
        gap: toRem(24);
    }

    @include media-breakpoint-up(md) {
        gap: toRem(32);

        .books-page & {
            gap: toRem(32);
        }
    }

    @include media-breakpoint-up(xl) {
        border-bottom: toRem(1) solid $gray;

        .books-page & {
            gap: toRem(24);
        }
    }
}

.dsm-cover-images-container {
    display: flex;
    justify-content: start;
    overflow-x: auto;
    gap: toRem(16);

    @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: max-content max-content max-content;
        justify-content: space-between;
        row-gap: toRem(32);
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: max-content max-content max-content max-content;
    }

    .cover-image {
        &.reversed {
            @include media-breakpoint-up(md) {
                max-width: toRem(230);
            }

            @media only screen and (min-width: 900px) { /*stylelint-disable-line*/
                max-width: toRem(250);
            }
            .cover-image__image {
                @media only screen and (min-width: 900px) { /*stylelint-disable-line*/
                    max-width: toRem(250);
                }

                img {
                    @include media-breakpoint-up(md) {
                        max-width: toRem(230);
                    }

                    @media only screen and (min-width: 900px) { /*stylelint-disable-line*/
                        max-width: toRem(250);
                        width: toRem(250);
                    }
                }
            }
        }
    }
}
