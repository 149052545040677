.podcast-journal {
    &__header {
        img {
            width: toRem(176);
            height: toRem(176);
        }

        a {
            text-decoration: underline;
        }
    }

    &__latest-episode {
        .ajp-audio {
            width: toRem(170);
            height: toRem(170);
        }

        &__card {
            background-color: $very-light-gray;
        }
    }

    &__all-episode {
        & > .branded-tabs {
            margin-bottom: toRem(10);
        }

        .tab-pane {
            & > .branded-tabs {
                margin-bottom: toRem(40);
            }
        }

        .branded-tabs {
            border-color: $gray;
            .nav-link--small {
                @include set-typography-type($text-small-bold);
            }
        }
    }
}
