.ajp-theme {
    @extend .ajp;
}

.focus-theme {
    @extend .foc;
}

@include media-breakpoint-up(lg) {
    .col-lg-offset-2 {
        margin-left: 8.32%;
    }
}

@include media-breakpoint-up(md) {
    .col-md-offset-2 {
        margin-left: 8.32%;
    }
}

.exam-disclosure {
    .citation__title {
        h1 {
            background-color: $primary-var;
            color: $white;
            @include set-typography-type($text-large-bold);
            padding: toRem(16);
            margin-bottom: toRem(20);
        }
    }

    .theme-color {
        color: $primary-var;
    }

    h2 {
        color: $primary-var;
        @include set-typography-type($text-large-bold);
        margin-bottom: toRem(16);

        span {
            @include set-typography-type($text-large-bold);
        }
    }

    p,
    span,
    a {
        @include set-typography-type($text-small);
        margin-bottom: toRem(14);
    }

    ul {
        li {
            @include set-typography-type($text-small);
        }
    }

    .btn {
        @extend .btn-primary;
        @include set-typography-type($text-regular-bold-16);
        padding: toRem(8) toRem(12);
        width: 100%;
        border-radius: initial;
        text-transform: capitalize;

        @include media-breakpoint-up(md) {
            min-width: toRem(150);
            width: auto;
        }
    }
}

.page__header--card {
    background-color: $primary-var;
    color: $white;
    @include set-typography-type($text-regular-bold);
    padding: toRem(16);
    margin-bottom: toRem(20);
}
