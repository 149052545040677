.institution {
    background-color: $black;
    opacity: initial;
    color: $gray;
    display: block;
    text-align: center;
    min-height: $institution-banner-height;
    z-index: map-get($map: $z-index-map-app, $key: 'institution-banner');

    @include media-breakpoint-up(md) {
        display: flex;
        height: $institution-banner-height;
    }

    img,
    a {
        height: 100%;
        margin-right: toRem(12);
    }

    &__image {
        max-height: 2.2em;
        @media only screen and (max-width: 410px) { /*stylelint-disable-line*/
            display: none;
        }
    }
}
