.top-content {
    .list-unstyled {
        padding-left: toRem(16);
        border-left: toRem(1) solid $gray;

        &.pn-top-content {
            padding: 0;
            border-left: 0;
        }
    }

    .pn-top-content {
        display: flex;
        flex-direction: column;
        gap: toRem(32);

        &__image {
            width: toRem(100);
            height: toRem(100);
            margin-bottom: toRem(24);

            @include media-breakpoint-up(md) {
                width: toRem(124);
                height: toRem(79);
                margin-right: toRem(24);
                margin-bottom: 0;
            }

            @include media-breakpoint-up(lg) {
                width: toRem(190);
                height: toRem(120);
            }
        }
    }

    .pdf {
        --primary: #{$pdf-color};
        --primary-darken-7: #{$pdf-tag-hover-bg-color};
    }
}
