.hero {
    &__scroller {
        @include media-breakpoint-down(md) {
            bottom: toRem(18);
        }
    }

    &__footer {
        position: absolute;
        display: flex;
        flex-flow: column;
        align-items: center;
        color: $hero-decoration-color;
        text-align: center;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: map-get($map: $z-index-map, $key: 'hero-footer');
    }

    &__concavity {
        margin-bottom: toRem(-5);
    }
}
