.quick-search {
    $this: &;

    &__single {
        input {
            @include set-typography-type($text-title-2);
            border: none;

            &::placeholder {
                @include set-typography-type($text-regular-bold);
            }
        }

        #{$this}__btn {
            @include set-typography-type($text-small-bold-uppercase);
            color: $primary;
            border-bottom: none;
            padding: 0;
        }
    }

    &--citation {
        .citation-search-btn {
            line-height: 1.5;
        }

        .dropdown-item {
            white-space: normal;
        }
    }

    &__items {
        .autoComplete {
            &__label {
                width: 70%;
                color: $deep-gray;
                font-weight: 500;
                white-space: wrap;

                .single_highlight_class {
                    font-weight: 700;
                }
            }

            &__category {
                width: 30%;
                white-space: wrap;
                margin-left: toRem(8);
            }
        }
    }
}

.autoComplete {
    z-index: $qucik-search-autocomplete-z-index;
}
