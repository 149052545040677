.sections-navigation {
    &__container {
        box-shadow: none;
    }

    &__btn {
        background-color: $primary-var;

        .icon-sections {
            color: $white;
        }
    }

    @include media-breakpoint-up(sm) {
        left: toRem(15);
    }

    .nav-link {
        color: $sections-navigation-links-color;
        text-transform: none;
        align-items: initial;
        font-size: 0.8em;

        &.active,
        &:hover {
            padding-left: 0;

            &::before {
                margin-left: toRem(8);
            }
        }

        @include media-breakpoint-up(xl) {
            max-inline-size: toRem(210);
            text-align: end;
        }
    }
}
