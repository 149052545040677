.counter-reports {
    &__list {
        li {
            margin-bottom: toRem(8);

            &::marker {
                font-size: toRem(22);
            }
        }
    }

    &__button {
        width: 100%;
        min-height: toRem(44);

        @include media-breakpoint-up(md) {
            width: auto;
            min-height: toRem(34);
        }
    }

    a {
        text-decoration: underline;
    }
}

.usage-reports {
    .alert {
        margin-bottom: toRem(24);
    }

    h5 {
        @include set-typography-type($text-regular-bold);
    }

    button {
        width: 100%;
        min-height: toRem(44);

        @include media-breakpoint-up(md) {
            width: auto;
            min-height: toRem(34);
        }
    }

    &__delivery-list {
        padding-left: toRem(24);

        h6 {
            @include set-typography-type($text-regular-bold-16);
        }

        li {
            &::marker {
                font-weight: 700;
            }
        }

        .custom-control {
            margin-bottom: toRem(8);
            &-label {
                @include set-typography-type($text-mini-bold-uppercase);
            }
        }
    }

    .sushi-table {
        &__label {
            @include media-breakpoint-up(md) {
                max-width: max-content;
            }

            @include media-breakpoint-up(lg) {
                max-width: 50%;
            }
        }

        &__value {
            @include media-breakpoint-up(md) {
                padding-left: 0;
            }

            @include media-breakpoint-up(lg) {
                flex-basis: 100%;
            }
        }
    }

    .select-year-wrapper {
        max-width: toRem(230);
    }

    .select-range-month {
        max-width: toRem(250);
    }

    .select-range-year {
        max-width: toRem(190);
    }
}
