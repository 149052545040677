.institution-statistics {
    &__date {
        &__input {
            &__wrapper {
                position: relative;
                input[type='date'] {
                    border: 0;
                    border-bottom: toRem(1) solid $light-gray;
                    padding: 0 0 toRem(6);
                    min-height: toRem(30);
                    width: 100%;

                    &::-webkit-datetime-edit {
                        @include set-typography-type($text-small);
                        color: $darker-gray;
                    }
                }

                .icon-component {
                    position: absolute;
                    top: toRem(5);
                    right: 0;
                    width: toRem(20);
                    height: toRem(20);
                    background: $white;
                    pointer-events: none;
                    color: $darker-gray;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    &__date-range {
        &__fields {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;

            @include media-breakpoint-up(md) {
                flex-direction: row;
                gap: toRem(25);
            }
        }

        &__from,
        &__to {
            @include media-breakpoint-up(md) {
                flex: 1;
            }
        }

        &__label {
            @include set-typography-type($text-mini);
        }
    }

    &__assessments {
        &__subject {
            &__select {
                &__toggle {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: toRem(1) solid $light-gray;
                    @include set-typography-type($text-small-bold);
                    color: $deep-gray;
                    width: 84vw;
                    max-width: toRem(400);

                    &.disabled {
                        pointer-events: none;
                        opacity: 0.5;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }

                &__list {
                    border: toRem(1) solid $light-gray;
                    @include set-typography-type($text-small-bold);
                    background-color: $white;
                    width: 84vw;
                    max-width: toRem(400);
                    max-height: toRem(350);
                    overflow: auto;
                    z-index: map-get($map: $z-index-map, $key: 'institution-statistics-assessments-subjects-list');

                    li {
                        &:last-child {
                            .form-group {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &__format {
        &__wrapper {
            display: flex;
            align-items: center;

            & > label {
                text-transform: capitalize;
            }
        }
    }

    &__mail {
        &.disabled {
            opacity: 0.5;
        }
    }

    .custom-control.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &__submit {
        width: 100%;
        min-height: toRem(44);

        @include media-breakpoint-up(md) {
            width: auto;
            min-height: toRem(34);
        }
    }
}
