.podcast-page {
    &__cards {
        margin-bottom: toRem(32);
    }

    &__card {
        img {
            width: toRem(100);
            height: fit-content;
        }
    }

    &__notes {
        ul {
            list-style-position: inside;
            padding-left: toRem(0);

            li {
                text-indent: toRem(-16);
                padding-left: toRem(16);
            }
        }
    }

    &__transcript {
        a {
            &:hover {
                text-decoration: none;
            }
        }
    }
}
