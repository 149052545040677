.test-history {
    &__info {
        padding-top: toRem(12);
        border-top: toRem(2) solid $primary;
    }

    &__top {
        &__exam-types {
            .exam-types {
                max-width: toRem(320);
            }
        }

        p {
            padding-top: toRem(16);
            border-top: toRem(2) solid $primary;
        }
    }

    &__histogram {
        background: $white;
        margin-bottom: toRem(29);
    }
}
