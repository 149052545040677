.link-resolver {
    .btn {
        width: 100%;
        min-height: toRem(44);

        @include media-breakpoint-up(md) {
            width: auto;
            min-height: toRem(34);
        }
    }

    .form-control {
        &::placeholder {
            color: $gray;
        }
    }

    &__file-wrapper {
        .form-group {
            @include media-breakpoint-up(md) {
                width: 50%;
            }
        }
    }

    .custom-file-label {
        height: toRem(38);
        padding-left: 0;

        &:after {
            bottom: toRem(5);
        }
    }
}
