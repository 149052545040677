.favorites {
    &-table-data {
        margin: 0;

        & > div {
            padding: 0;

            @include media-breakpoint-up(md) {
                padding-right: toRem(12);
            }

            @include media-breakpoint-up(lg) {
                padding-right: toRem(16);
            }
        }

        @include media-breakpoint-up(md) {
            @include set-typography-type($text-mini);
        }
    }

    .custom-control-label {
        white-space: nowrap;
    }

    .table-expand-row {
        @include media-breakpoint-up(md) {
            right: toRem(-5);
        }
    }

    .headings {
        border-bottom: toRem(2) solid $gray;
        @include media-breakpoint-up(md) {
            div {
                padding-left: 0;
            }
        }
    }

    &__select-all {
        @include media-breakpoint-up(md) {
            max-width: toRem(91);
        }
    }

    &__action-select {
        @include media-breakpoint-up(md) {
            max-width: toRem(280);
        }
    }
}
