.card {
    .btn-authors-truncate {
        color: $dark-gray;
        border-color: $dark-gray;

        .announcement & {
            color: $white;
            border-color: $white;
        }
    }

    .brand-tag {
        padding: toRem(2) 0;
        border-radius: toRem(28);
    }

    .multi-search &-category + span {
        padding: toRem(2) 0;
    }

    .meta-tags {
        gap: toRem(8);
    }

    .meta-tag {
        @include set-typography-type($text-micro-uppercase);
        color: $deep-gray;
        border-radius: toRem(28);
        padding: toRem(2) toRem(12);
        background-color: var(--secondary);
    }

    .text-meta-container {
        min-height: toRem(18);
        align-items: center;
        border-left: toRem(1) solid $darker-gray;

        & > .meta {
            line-height: 1;
        }

        .meta {
            & > span:not(.meta) {
                display: inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 15rem;
                line-height: 1;

                @include media-breakpoint-up(sm) {
                    max-width: 19rem;
                }

                @include media-breakpoint-up(md) {
                    max-width: 23rem;
                }

                @include media-breakpoint-up(md) {
                    max-width: 33rem;
                }
            }
        }
    }

    &-content {
        .search-result__body & {
            width: 100%;
        }
    }
}

.article-title-5 {
    @extend .text-title-5;
}

.podcast-card {
    &:first-of-type {
        border-bottom: toRem(1) solid $gray;

        @include media-breakpoint-up(md) {
            border-bottom: none;
            border-right: toRem(1) solid $gray;
        }
    }

    .animation-icon-shift {
        &:hover {
            text-decoration: underline;
        }
    }

    .card-body {
        @include set-typography-type($text-small);
    }
}

.toc__section,
.search-result__body {
    .card-formats {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: center;

        @include media-breakpoint-up(md) {
            display: flex;
        }

        & > .format-btn {
            &-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                width: 100%;
                border-right: toRem(1) solid $light-gray;
                border-left: toRem(1) solid $light-gray;
                border-radius: initial;
                justify-self: center;

                @include media-breakpoint-up(md) {
                    width: max-content;
                }

                & > a {
                    padding: 0 toRem(35);
                    @include media-breakpoint-up(md) {
                        padding: 0 toRem(8);
                    }
                    color: $dark-gray;
                }

                &:first-of-type {
                    justify-content: start;
                    border-left: none;
                    & > a {
                        color: $primary-var;
                        padding: 0;

                        @include media-breakpoint-up(md) {
                            padding-right: toRem(8);
                        }
                    }
                }

                &:last-of-type {
                    justify-content: end;
                    border-right: none;
                    & > a {
                        padding: 0;

                        @include media-breakpoint-up(md) {
                            padding-left: toRem(8);
                        }
                    }
                }
            }
        }

        .format-btn__text {
            @include set-typography-type($text-mini-bold-uppercase);
            color: $darker-gray;
        }
    }
}
