.search-result {
    .sidebar__overlay {
        z-index: map-get($map: $z-index-map-app, $key: 'sidebar-overlay');
    }

    &__refine {
        @include set-typography-type($text-mini-bold-uppercase);
        color: $darker-gray;
        padding: 0;

        &::before {
            display: none;
        }

        &:after {
            @include rectangle($search-result-refine-search-toggle-icon-size);
            vertical-align: middle;
            border-width: toRem(2);
            margin-bottom: toRem(2);
        }
    }

    &__body {
        @include media-breakpoint-up(md) {
            margin-top: toRem(40);
        }

        &.psych-search-result {
            margin: toRem(40) 0 toRem(48);

            @include media-breakpoint-up(md) {
                margin: toRem(56) 0 toRem(48);
            }
        }
    }

    &__nav {
        @include set-typography-type($text-mini-bold-uppercase);
        padding: toRem(16);
        margin-bottom: 2.5rem;

        .container-fluid {
            flex-direction: column;
            align-items: start;
            gap: toRem(16);
            flex-wrap: wrap;
            padding: 0;

            @include media-breakpoint-up(md) {
                flex-direction: row;
                align-items: center;
            }

            @include media-breakpoint-up(xl) {
                padding: 0 toRem(16);
            }
        }

        @include media-breakpoint-up(xl) {
            padding: toRem(16) toRem(112);
        }

        &__filter {
            .btn {
                max-width: max-content;
            }
        }

        &__actions {
            width: 100%;
            @include media-breakpoint-up(md) {
                width: auto;
            }

            &__item {
                font-size: inherit;

                .btn {
                    color: inherit;
                }

                .icon-component svg {
                    width: toRem(24);
                    height: toRem(24);
                }
            }
        }
    }

    .per-page {
        .dropdown-toggle {
            padding: 0;
            @include set-typography-type($text-mini-bold-uppercase);
            color: $black;
        }

        &__btn {
            &:focus {
                box-shadow: none;
            }
        }

        &__label {
            @include set-typography-type($text-mini-bold-uppercase);
            color: $darker-gray;
        }
    }

    &__sort {
        .dropdown-toggle {
            padding: 0;
            @include set-typography-type($text-mini-bold-uppercase);
            color: $black;
        }

        &-menu {
            right: initial;
        }
    }

    &__content {
        .nav {
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
        }

        .branded-tabs {
            margin-bottom: toRem(40);
        }

        .pagination__nav {
            .icon-component {
                svg {
                    height: toRem(24);
                    width: toRem(24);
                }
            }
        }
    }

    &__tab {
        white-space: nowrap;
        @include set-typography-type($text-small-bold-uppercase);

        &__item {
            padding: toRem(16) toRem(10);
            min-width: toRem(164);

            &.nav-link.active {
                border-color: $primary;
            }
        }
    }

    &__sidebar {
        @include media-breakpoint-up(xl) {
            &.sidebar.show {
                transform: translateX(-6.25vw);

                & ~ .search-result__content {
                    margin-left: -6.25vw !important; // to overwrite Generic Extend
                    margin-right: -8.75vw;
                }
            }
        }
    }

    .article-actionbar__btns--hide {
        display: flex;
    }

    &__advanced {
        @include media-breakpoint-up(md) {
            z-index: map-get($map: $z-index-map-app, $key: 'search-results-advanced');
        }

        @include media-breakpoint-up(xl) {
            &.show {
                width: toRem(1086);
                left: toRem(-47);
            }
        }

        // to override the extended classes
        .tab-pane {
            @include media-breakpoint-up(md) {
                padding: 0 !important;
            }

            @include media-breakpoint-up(lg) {
                padding: 0 toRem(64) !important;
            }
        }
    }

    &__books {
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 0;

        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr 1fr;
            column-gap: toRem(16);

            &.book-topic-search-result {
                grid-template-columns: 1fr;
            }
        }

        & + .pagination__nav {
            margin-top: toRem(16);
        }

        .search-item {
            &__image {
                max-width: toRem(176);

                img {
                    max-height: toRem(260);
                }
            }

            &__title {
                margin: toRem(8) 0;
            }

            &__abstract {
                .accordion__toggle {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .authors-toggle {
                display: block;
            }

            .btn-authors-truncate {
                color: $dark-gray;
                border-color: $dark-gray;
                @include set-typography-type($text-mini-bold-uppercase);
                padding: toRem(8);
            }
        }
    }

    &.showPublications {
        .search-result__quick-search {
            margin-bottom: 0;
        }

        .search-result__body {
            margin-top: 0;
        }
    }

    .psych-search-item {
        &__image {
            min-width: toRem(100);
            img {
                width: 10rem;
                height: auto;
            }
        }

        .card-header {
            a {
                color: $deep-gray;
            }
        }
    }
}
