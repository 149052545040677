.multi-search {
    $this: &;

    &__more {
        gap: toRem(4);
    }

    &.psych-news-topic-wrapper {
        .list-unstyled {
            display: grid;
            grid-template-columns: 1fr;
            row-gap: toRem(32);

            @include media-breakpoint-up(md) {
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: toRem(32);
            }

            @include media-breakpoint-up(lg) {
                row-gap: toRem(48);
            }
        }
    }
}

.book-slideshow {
    .swiper-slide {
        width: auto;
    }

    .swiper-container {
        padding: toRem(32) toRem(8);
    }

    .books-page & {
        .cover-image.reversed {
            max-width: toRem(165);
            img {
                height: toRem(250);
                width: auto;
                max-width: toRem(165);
            }
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        top: 50%;
    }

    .swiper-button-container {
        @include media-breakpoint-down(lg) {
            max-width: 100%;
            left: 0;
        }
    }
}
