.exam-types {
    .btn {
        border-bottom: toRem(2) solid $primary;
        width: 100%;
        margin-bottom: toRem(12);
        color: $primary;

        @include media-breakpoint-up(md) {
            min-width: toRem(320);
            margin-bottom: 0;
        }
    }
}
