.publication-menu {
    &.collapsing {
        position: fixed;
    }

    &.sidebar {
        transform: translateX(100vw);

        &.show {
            transform: translateX(0);
        }

        @include media-breakpoint-up(md) {
            transform: translateX(21.25rem);
        }

        @include media-breakpoint-up(xl) {
            transform: translateX(0);
        }
    }

    @include media-breakpoint-up(md) {
        width: toRem(340);
        transform: translateX(toRem(340));
    }

    &__journal-logo {
        img {
            max-width: toRem(90);

            .jnp &,
            .foc &,
            .apt &,
            .prcp & {
                max-width: toRem(120);
            }

            .pn & {
                max-width: toRem(160);
            }

            @include media-breakpoint-up(md) {
                max-width: max-content;

                .jnp &,
                .foc &,
                .apt &,
                .prcp & {
                    max-width: max-content;
                }

                .pn & {
                    max-width: max-content;
                }
            }
        }
    }

    &__nav {
        p {
            border-bottom: toRem(1) solid $gray;
        }
    }

    &__btns {
        width: 100%;
        gap: toRem(16);

        @include media-breakpoint-up(xl) {
            gap: toRem(10);
        }
    }

    &__btn {
        color: $deep-gray;
        border: toRem(1) solid $deep-gray;
        border-radius: toRem(70);
        max-height: toRem(44);
        height: toRem(44);
        padding: toRem(8) toRem(16);

        @include media-breakpoint-up(md) {
            max-height: toRem(40);
            height: toRem(40);
        }

        @include media-breakpoint-up(xl) {
            white-space: nowrap;
            max-height: toRem(32);
            height: toRem(32);
            color: $white;
            border: toRem(1) solid $white;
            padding: toRem(4) toRem(16);

            &:hover {
                background-color: $white;
                color: $primary-var;
                text-decoration: none;
            }
        }
    }

    &__links {
        width: 100%;

        @include media-breakpoint-up(xl) {
            gap: toRem(10);
        }
    }

    &__link {
        color: $deep-gray;
        gap: toRem(8);

        .icon-component {
            svg {
                height: 100%;
                width: 100%;
            }
        }

        @include media-breakpoint-up(xl) {
            color: $white;

            &:hover {
                color: var(--secondary);
            }
        }
    }

    @include media-breakpoint-up(xl) {
        all: unset;
        flex: 1;
        color: $white;
    }

    .navbar-nav {
        .nav-item {
            &:not(.nav-item--quick-search) {
                .nav-link {
                    color: $deep-gray;

                    @include media-breakpoint-up(md) {
                        padding: toRem(16) 0;
                    }

                    @include media-breakpoint-up(xl) {
                        color: $white;
                        padding: toRem(8) 0;
                    }
                }

                .dropdown {
                    &-toggle {
                        color: $deep-gray;

                        @include media-breakpoint-up(md) {
                            padding: toRem(16);
                        }

                        @include media-breakpoint-up(xl) {
                            color: $white;
                            padding: toRem(8) 0;
                        }

                        &::before {
                            display: none;

                            @include media-breakpoint-up(xl) {
                                background: $white;
                                display: block;
                            }
                        }

                        &:after {
                            margin-left: toRem(12);
                        }
                    }

                    &-item {
                        @include media-breakpoint-up(md) {
                            padding: toRem(16);
                        }

                        @include media-breakpoint-up(xl) {
                            padding: toRem(12);
                            min-width: toRem(330);
                        }

                        &:hover {
                            background-color: $primary-var;
                            color: $white;
                        }
                    }

                    &-menu {
                        @include media-breakpoint-down('xl') {
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }

    .share-links {
        @include media-breakpoint-up(xl) {
            height: toRem(24);
        }

        .dsm & {
            p {
                display: none;
            }
        }
    }
}

.secondary-navbar {
    background-color: $primary-var;
    z-index: map-get($map: $z-index-map-app, $key: 'secondary-navbar');

    .navbar-in {
        border: 0;
        padding: 0;

        .secondary-navbar-toggler {
            .navbar-toggler {
                display: flex;
                @include set-typography-type($text-regular);

                &.hidden {
                    display: none;
                }
            }
        }
    }
}
