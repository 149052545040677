.alerts {
    &__actions {
        .custom-control-label {
            text-transform: capitalize;
            color: $deep-gray;
        }

        .remove-article-btn {
            span:first-of-type {
                margin-right: toRem(10);
            }
        }
    }

    &__sort-dropdown {
        padding: 0;

        label {
            white-space: nowrap;
        }

        .form-control {
            flex: 1;
            border-color: $gray;
            max-width: 100%;
        }
    }
}

.manage-alerts {
    .alert-info {
        color: $primary;
        background-color: $secondary;
        border-color: $secondary;
    }
}
