.card--ocean {
    background-color: $card-ocean-bg-color;

    .page-link {
        background-color: $card-ocean-bg-color;
    }
}

.create-test,
.evaluation__form {
    $this: &;

    padding: 0 toRem(16) toRem(16);

    &__options-details {
        .card {
            padding: 0 toRem(16);
        }

        h4 {
            color: $deep-gray;
            @include set-typography-type($text-regular-bold);
            margin-bottom: toRem(15);
        }

        h5 {
            color: $primary-var;
            @include set-typography-type($text-small-bold);
            margin: toRem(10) 0;
        }

        p {
            @include set-typography-type($text-small);
        }

        .rlist {
            padding: 0;
            list-style: none;
        }
    }

    &__question {
        margin-bottom: toRem(24);

        &:last-child {
            margin-bottom: toRem(16);
        }

        &__title {
            @include set-typography-type($text-regular-bold);
            margin-bottom: toRem(12);

            .create-test__question__count & {
                margin-bottom: toRem(8);
            }
        }

        &__options {
            label {
                margin-bottom: 0;
            }

            .input-group {
                margin-bottom: 1rem;

                &.js--has-options {
                    flex-direction: column;
                }

                &:last-child {
                    margin-bottom: toRem(26);
                }
            }
        }
    }

    &.loading {
        opacity: 0.5;
    }

    &__loading {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__error {
        display: none;
        margin-top: toRem(16);
        padding: toRem(20);
        color: $deep-gray;
        border: toRem(3) solid $danger;

        &.active {
            display: flex;
        }
    }

    &__available-questions {
        overflow: hidden;
        max-height: 0;
        transition: all 0.25s;
        margin-bottom: 0.25rem;

        &.active {
            margin-bottom: 0;
            padding: toRem(16);
            max-height: 100vh;
            overflow: visible;
            border: toRem(3) solid $success;
            margin-top: toRem(16);
        }
    }

    input,
    textarea {
        background: $white;
        border: none;
        padding: toRem(14);
        margin-top: toRem(14);
        display: block;
    }

    &__info {
        text-transform: capitalize;
    }

    &__count {
        input {
            width: 100%;

            @include media-breakpoint-up(md) {
                width: toRem(400);
            }
        }
    }

    label {
        text-transform: capitalize;
    }
}

.radio-options {
    overflow: hidden;
    max-height: 0;
    transition: all 0.4s cubic-bezier(0, 0, 0.34, 0);
    visibility: hidden;
    opacity: 0;

    label {
        margin-bottom: toRem(16);
    }

    .active & {
        visibility: visible;
        opacity: 1;
        overflow: visible;
        margin-top: toRem(26);
        max-height: 100%;
        margin-bottom: 0.625rem;
    }
}
