.redeem-voucher {
    button {
        width: 100%;
        min-height: toRem(44);

        @include media-breakpoint-up(md) {
            width: auto;
            min-height: toRem(34);
        }
    }

    .message {
        @include set-typography-type($text-small-bold);
        &.error {
            color: $danger;
        }

        &.success {
            color: $success;
        }
    }
}
