.share {
    .dropblock {
        &__content {
            padding: 0;
            border: toRem(1) solid $very-light-gray;
            box-shadow: 0 toRem(6) toRem(6) 0 rgba(0, 0, 0, 0.09);
            &.collapsing {
                position: absolute;
            }

            &-inner {
                padding: toRem(16);
            }

            .a2a {
                @include media-breakpoint-up(xl) {
                    grid-template-columns: 1fr;
                    row-gap: toRem(4);
                }
            }
        }
    }

    .publication-menu & {
        .dropblock {
            &__control {
                &:hover {
                    color: var(--secondary);
                }

                &-link {
                    .icon-component {
                        svg {
                            height: toRem(24);
                            width: toRem(24);
                        }
                    }
                }

                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }

            &__content {
                top: toRem(48);
                right: toRem(0);
                border: toRem(1) solid $very-light-gray;
                box-shadow: 0 toRem(6) toRem(6) 0 rgba(0, 0, 0, 0.09);
                min-width: toRem(200);
                padding: 0;

                &-inner {
                    padding: toRem(16);
                }

                &.collapsing {
                    position: absolute;
                }

                @include media-breakpoint-down(lg) {
                    display: block;
                    position: initial;
                    padding: 0;
                    top: unset;
                    right: unset;
                    border: unset;
                    box-shadow: unset;
                    min-width: unset;

                    &-inner {
                        padding: 0;
                    }
                }
            }
        }

        .a2a {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            row-gap: toRem(32);
            column-gap: toRem(32);

            & > div {
                display: none;
            }

            @include media-breakpoint-up(xl) {
                grid-template-columns: 1fr;
                row-gap: toRem(16);
            }
        }

        &__link {
            @include set-typography-type($text-small-bold);
            color: $deep-gray;
            gap: toRem(8);

            .at-icon-wrapper {
                font-size: toRem(32);
            }
        }
    }
}
