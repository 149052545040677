.profile {
    &-header {
        text-transform: capitalize;
    }
}

.managed-institutions {
    .form-control {
        margin-left: toRem(-3);
    }
}

.profile-main {
    a {
        text-decoration: underline;
    }

    .page-link {
        text-decoration: none;
    }

    .account-tab__nav,
    .nav-tabs {
        .nav-link {
            text-decoration: none;
        }
    }

    .accordion-table {
        .headings {
            a {
                text-decoration: none;
            }
        }
    }

    .small {
        font-size: 88%;
    }
}
